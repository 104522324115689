////
/// @group wk-css.components.switch
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.20.0
/// @access public
////

@import 'switch.variables';
@import 'switch.mixins';
@import 'switch.placeholders';

.wk-switch {
    @extend %wk-switch;
}
