////
/// @group wk-css.components.alerts
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.17.0
/// @access public
////

%wk-alert {
    background-color: $wk-alert-bg-color;
    border-color: $wk-alert-border-color;
    border-style: $wk-alert-border-style;
    border-width: $wk-alert-border-width;
    font-family: $wk-alert-font-family;
    font-weight: $wk-alert-font-weight;
    margin-bottom: $wk-alert-margin-bottom;
    padding: $wk-alert-padding;
    position: $wk-alert-position;

    &[wk-icon] {
        display: $wk-alert-icon-display;
        font-weight: $wk-alert-icon-font-weight;
        line-height: $wk-alert-icon-line-height;
        margin-left: $wk-alert-icon-margin-left;
        margin-right: $wk-alert-icon-margin-right;
        padding-left: $wk-alert-icon-padding-left;
        speak: normal;
        text-align: $wk-alert-icon-text-align;
        width: $wk-alert-icon-width;

        &::before {
            bottom: $wk-alert-icon-before-bottom;
            color: $wk-alert-icon-before-color;
            font-size: $wk-alert-icon-before-font-size;
            left: $wk-alert-icon-before-left;
            line-height: $wk-alert-icon-before-line-height;
            margin-left: $wk-alert-icon-before-margin-left;
            margin-right: $wk-alert-icon-before-margin-right;
            padding-bottom: $wk-alert-icon-before-padding-bottom;
            padding-left: $wk-alert-icon-before-padding-left;
            padding-right: $wk-alert-icon-before-padding-right;
            padding-top: $wk-alert-icon-before-padding-top;
            position: $wk-alert-icon-before-position;
            speak: none;
            top: $wk-alert-icon-before-top;
            width: $wk-alert-icon-before-width;
        }
    }

    a {
        color: $wk-alert-link-color;
        text-decoration: $wk-alert-link-text-decoration;
    }

    .wk-alert-title {
        font-weight: map-get($wk-font-weight, bold);
    }

    .wk-alert-title,
    > p {
        margin-bottom: wk-rem(5px);
    }
}

%wk-alert-info {
    @include wk-alert-variant($wk-alert-info-border-color, $wk-alert-info-color, $wk-alert-info-icon-bg-color);
}

%wk-alert-success {
    @include wk-alert-variant($wk-alert-success-border-color, $wk-alert-success-color, $wk-alert-success-icon-bg-color);
}

%wk-alert-warning {
    @include wk-alert-variant($wk-alert-warning-border-color, $wk-alert-warning-color, $wk-alert-warning-icon-bg-color);
}

%wk-alert-danger {
    @include wk-alert-variant($wk-alert-danger-border-color, $wk-alert-danger-color, $wk-alert-danger-icon-bg-color);
}

%wk-alert-dismissible {
    padding-right: $wk-alert-dismissible-padding-right;

    #{$wk-alert-dismiss},
    .wk-icon-close-empty {
        background-color: $wk-alert-dismiss-bg-color;
        color: inherit;
        position: $wk-alert-dismiss-position;
        transition: $wk-alert-dismiss-transition;
        width: $wk-alert-dismiss-width;

        &:hover:not([disabled]) {
            color: inherit;
        }
    }

    #{$wk-alert-dismiss} {
        font-size: $wk-alert-dismiss-font-size;
        padding-bottom: $wk-alert-dismiss-padding-bottom;
        padding-left: $wk-alert-dismiss-padding-left;
        padding-right: $wk-alert-dismiss-padding-right;
        padding-top: $wk-alert-dismiss-padding-top;
        right: $wk-alert-dismiss-right;
        top: $wk-alert-dismiss-top;
    }

    #{$wk-alert-dismiss}:hover,
    .wk-icon-close-empty:hover {
        background-color: $wk-alert-dismiss-hover-bg-color;
        opacity: $wk-alert-dismiss-hover-opacity;
    }

    .wk-icon-close-empty {
        font-size: wk-rem(38px);
        margin: 0;
        padding: 0;
        right: wk-rem(4px);
        top: wk-rem(4px);
    }
}

%wk-alert-fixed {
    border-left: $wk-alert-fixed-border-left;
    border-right: $wk-alert-fixed-border-right;
    left: $wk-alert-fixed-left;
    position: $wk-alert-fixed-position;
    right: $wk-alert-fixed-right;
    top: $wk-alert-fixed-top;
    transform: $wk-alert-fixed-transform;
    transition: $wk-alert-fixed-transition;
    z-index: $wk-alert-fixed-z-index;

    &#{$wk-alert-open} {
        transform: $wk-alert-open-transform;
    }
}
