////
/// @group wk-css.components.buttons
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.18.3
/// @access public
////

%wk-button {
    @include wk-transition(background-color);
    appearance: none;
    background-color: $wk-button-standard-background-color;
    border: $wk-button-border;
    border-radius: $wk-button-border-radius;
    color: $wk-button-standard-color;
    font-family: $wk-headings-font-stack;
    font-size: map-get($wk-font-scale, smaller);
    font-weight: map-get($wk-font-weight, regular);
    line-height: $wk-button-line-height;
    margin-bottom: $wk-button-separation;
    padding: $wk-button-padding;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    width: 100%;

    @include wk-has-context();

    @include breakpoint($wk-target-tablet-desktop) {
        display: inline-block; //buttons only take up what they need per their text content when non-mobile
        margin-bottom: 0;
        width: auto;
    }

    &[wk-icon] {
        //combine [wk-icon] and [wk-button] and you need to set the button font to override the [wk-icon] styles
        speak: normal;
        margin-left: 0;
        margin-right: 0;
        width: 100%;

        &::before {
            speak: none;
        }

        @include breakpoint($wk-target-tablet-desktop) {
            width: auto;
        }
    }

    &:hover:not([disabled]) {
        background-color: $wk-button-standard-hover-background-color;
        color: $wk-button-standard-hover-color;
    }

    .wk-button-icon-right,
    &[wk-button*='icon-right'] {
        @include wk-button-icon;
    }

    .wk-button-icon-left,
    &[wk-button*='icon-left'] {
        @include wk-button-icon(left);
    }

    &.wk-button-secondary,
    &[wk-button*='secondary'] {
        background-color: $wk-button-secondary-background-color;
        color: $wk-button-secondary-color;

        &:hover:not([disabled]) {
            background-color: $wk-button-secondary-hover-background-color;
            color: $wk-button-secondary-color;
        }
    }

    &.wk-button-success,
    &[wk-context='success'] {
        @include wk-context(success);

        &:hover:not([disabled]) {
            background-color: $wk-button-success-hover-background-color;
        }
    }

    &.wk-button-danger,
    &[wk-context='danger'] {
        @include wk-context(danger);

        &:hover:not([disabled]) {
            background-color: $wk-button-danger-hover-background-color;
        }
    }

    &.wk-button-warning,
    &[wk-context='warning'] {
        @include wk-context(warning);

        &:hover:not([disabled]) {
            background-color: $wk-button-warning-hover-background-color;
        }
    }

    &[disabled] {
        opacity: $wk-button-disabled-opacity;
    }

    &.wk-button-full,
    &[wk-button*='full'] {
        width: 100%;
    }
}

%wk-button-link {
    display: block;
    text-align: center;

    @include breakpoint($wk-target-tablet-desktop) {
        display: inline-block;
    }

    &:hover {
        color: $wk-button-standard-hover-color;
    }

    .wk-button-link,
    &[wk-button*='link'] {
        @include wk-button-icon(left, transparent);
        background-color: transparent;
        color: wk-primary-color(wkGray);
        margin-bottom: 0;
        padding: 0;
        padding-left: 1.25em;

        &:hover {
            background-color: transparent;
            color: wk-primary-color(wkGray);
            text-decoration: underline;
        }

        &::before {
            color: wk-primary-color(wkGray);
            padding: 0;
            text-decoration: none;
            width: 3px;
        }
    }
}

%wk-button-bar {
    @include container;

    .wk-button-group-left {
        @include breakpoint($wk-target-tablet-desktop) {
            float: left;
        }

        + .wk-button-group-right {
            margin-top: $wk-button-separation; //violates our principal but the exact selector we need!

            @include breakpoint($wk-target-tablet-desktop) {
                margin-top: 0;
            }
        }
    }

    .wk-button-group-right {
        @include breakpoint($wk-target-tablet-desktop) {
            float: right;
        }
    }

    button,
    .wk-button,
    [wk-button] {
        @include breakpoint($wk-target-tablet-desktop) {
            float: left;
            margin-bottom: 0;
            margin-right: $wk-button-separation;

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
