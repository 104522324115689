/**
 * WK CSS - WK CSS framework provided by the GPO.
 * @version v0.26.0
 * @revision b713cbc
 * @build September 13, 2017 19:01:42
 * @copyright Copyright © 2017, Wolters Kluwer. All rights reserved.
 */
@charset "UTF-8";
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

h1,
h2,
h3,
h4,
h5,
h6,
.wk-h1,
.wk-h2,
.wk-h3,
.wk-h4,
.wk-h5,
.wk-h6 {
  margin-bottom: .3em;
  margin-top: 1em; }

input[type='search'] {
  box-sizing: border-box; }

input:not([type='radio']):not([type='checkbox']),
textarea,
select {
  border-radius: 0; }

button {
  background: none; }

img {
  height: auto;
  max-width: 100%; }

body {
  background-color: #fff; }

body,
html {
  color: #474747;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4; }

blockquote,
address,
p,
ul,
ol,
dl {
  font-size: 0.9375rem;
  margin-bottom: 1em;
  margin-top: 0; }
  blockquote:last-child,
  address:last-child,
  p:last-child,
  ul:last-child,
  ol:last-child,
  dl:last-child {
    margin-bottom: 0; }

cite,
small {
  font-size: 0.9375rem; }

::-moz-selection {
  background-color: #d4e8b1; }

::selection {
  background-color: #d4e8b1; }

h1,
h2,
h3,
h4,
h5,
h6,
.wk-h1,
.wk-h2,
.wk-h3,
.wk-h4,
.wk-h5,
.wk-h6 {
  color: #474747;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  margin-bottom: 0.25rem;
  margin-top: 1rem; }
  h1.subset,
  h2.subset,
  h3.subset,
  h4.subset,
  h5.subset,
  h6.subset,
  .wk-h1.subset,
  .wk-h2.subset,
  .wk-h3.subset,
  .wk-h4.subset,
  .wk-h5.subset,
  .wk-h6.subset {
    font-weight: 200; }

h1,
.wk-h1 {
  font-size: 2.0736rem;
  font-weight: 700; }

h2,
.wk-h2 {
  font-size: 1.728rem;
  font-weight: 700; }

h3,
.wk-h3 {
  font-size: 1.44rem;
  font-weight: 700; }

h4,
.wk-h4 {
  font-size: 1.2rem;
  font-weight: 600; }

h5,
.wk-h5 {
  font-size: 1rem;
  font-weight: 400; }

h6,
.wk-h6 {
  font-size: 1rem;
  font-weight: 300; }

a {
  color: #007ac3;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.1s ease-out; }
  a:hover {
    color: #85bc20; }

code {
  background-color: #f6f6f6;
  border: 1px solid #ededed;
  border-radius: 0;
  color: #474747;
  padding: .1rem .4rem; }

code,
pre,
kbd,
samp {
  font-family: "DejaVu Sans Mono", Consolas, Menlo, Monaco, "Lucida Console", "Bitstream Vera Sans Mono", "Courier New", monospace;
  font-size: 0.8125rem; }

pre code {
  background-color: transparent;
  border: 0; }

.wk-code-block {
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word; }

blockquote {
  border-left-color: #dadada;
  border-left-style: solid;
  border-left-width: 4px;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  margin: 0 0 1.2rem;
  padding-bottom: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.75rem; }
  blockquote address,
  blockquote blockquote,
  blockquote dl,
  blockquote p,
  blockquote ol,
  blockquote ul {
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif; }
  blockquote cite {
    display: inline-block; }
  blockquote > footer {
    color: #757575;
    font-size: 80%;
    margin-top: 1.25rem; }
    blockquote > footer::before {
      content: '\2014 \00A0'; }

mark {
  background-color: #f8d8a6;
  padding: 0.125rem; }

kbd {
  background-color: #757575;
  color: #fff;
  padding: .1rem .4rem; }

.wk-alert, .wk-alert-info, .wk-alert-success, .wk-alert-warning, .wk-alert-danger {
  background-color: #fff;
  border-color: #474747;
  border-style: solid;
  border-width: 1px;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-weight: 300;
  margin-bottom: 1.25rem;
  padding: 0.75rem 1rem;
  position: relative; }
  [wk-icon].wk-alert, [wk-icon].wk-alert-info, [wk-icon].wk-alert-success, [wk-icon].wk-alert-warning, [wk-icon].wk-alert-danger {
    display: block;
    font-weight: 300;
    line-height: inherit;
    margin-left: 0;
    margin-right: 0;
    padding-left: 3.8125rem;
    speak: normal;
    text-align: left;
    width: auto; }
    [wk-icon].wk-alert::before, [wk-icon].wk-alert-info::before, [wk-icon].wk-alert-success::before, [wk-icon].wk-alert-warning::before, [wk-icon].wk-alert-danger::before {
      bottom: 0;
      color: #fff;
      font-size: 1.4375rem;
      left: 0;
      line-height: 1em;
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 0.6875rem;
      padding-left: 0.6875rem;
      padding-right: 0.6875rem;
      padding-top: 0.6875rem;
      position: absolute;
      speak: none;
      top: 0;
      width: auto; }
  .wk-alert a, .wk-alert-info a, .wk-alert-success a, .wk-alert-warning a, .wk-alert-danger a {
    color: inherit;
    text-decoration: underline; }
  .wk-alert .wk-alert-title, .wk-alert-info .wk-alert-title, .wk-alert-success .wk-alert-title, .wk-alert-warning .wk-alert-title, .wk-alert-danger .wk-alert-title {
    font-weight: 700; }
  .wk-alert .wk-alert-title, .wk-alert-info .wk-alert-title, .wk-alert-success .wk-alert-title, .wk-alert-warning .wk-alert-title, .wk-alert-danger .wk-alert-title, .wk-alert > p, .wk-alert-info > p, .wk-alert-success > p, .wk-alert-warning > p, .wk-alert-danger > p {
    margin-bottom: 0.3125rem; }

.wk-alert[wk-context*='info'], .wk-alert-info {
  border-color: #007ac3;
  color: #007ac3; }
  [wk-icon].wk-alert[wk-context*='info']::before, [wk-icon].wk-alert-info::before {
    background-color: #007ac3; }

.wk-alert[wk-context*='success'], .wk-alert-success {
  border-color: #85bc20;
  color: #85bc20; }
  [wk-icon].wk-alert[wk-context*='success']::before, [wk-icon].wk-alert-success::before {
    background-color: #85bc20; }

.wk-alert[wk-context*='warning'], .wk-alert-warning {
  border-color: #ea8f00;
  color: #ea8f00; }
  [wk-icon].wk-alert[wk-context*='warning']::before, [wk-icon].wk-alert-warning::before {
    background-color: #ea8f00; }

.wk-alert[wk-context*='danger'], .wk-alert-danger {
  border-color: #e5202e;
  color: #e5202e; }
  [wk-icon].wk-alert[wk-context*='danger']::before, [wk-icon].wk-alert-danger::before {
    background-color: #e5202e; }

.wk-alert-dismissible {
  padding-right: 2.875rem; }
  .wk-alert-dismissible .wk-alert-dismiss, .wk-alert-dismissible .wk-icon-close-empty {
    background-color: transparent;
    color: inherit;
    position: absolute;
    transition: opacity 0.2s;
    width: auto; }
    .wk-alert-dismissible .wk-alert-dismiss:hover:not([disabled]), .wk-alert-dismissible .wk-icon-close-empty:hover:not([disabled]) {
      color: inherit; }
  .wk-alert-dismissible .wk-alert-dismiss {
    font-size: 1.375rem;
    padding-bottom: 0.4375rem;
    padding-left: 0.4375rem;
    padding-right: 0.4375rem;
    padding-top: 0.4375rem;
    right: 0.4375rem;
    top: 0.25rem; }
  .wk-alert-dismissible .wk-alert-dismiss:hover, .wk-alert-dismissible .wk-icon-close-empty:hover {
    background-color: transparent;
    opacity: 0.6; }
  .wk-alert-dismissible .wk-icon-close-empty {
    font-size: 2.375rem;
    margin: 0;
    padding: 0;
    right: 0.25rem;
    top: 0.25rem; }

.wk-alert-fixed {
  border-left: 0;
  border-right: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
  transition: 0.2s;
  z-index: 9999; }
  .open.wk-alert-fixed {
    -ms-transform: translateY(0);
        transform: translateY(0); }

.wk-spin {
  animation: spin 2s infinite linear;
  display: inline-block; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.wk-badge,
[wk-badge] {
  background-color: #007ac3;
  border-radius: 1rem;
  color: #fff;
  font-size: 0.77248em;
  font-weight: 700;
  padding: 0.125rem 0.5rem;
  position: relative;
  text-align: center;
  top: -.1em;
  white-space: nowrap; }
  button .wk-badge,
  .wk-button .wk-badge,
  .wk-button-full .wk-badge, button
  [wk-badge],
  .wk-button
  [wk-badge],
  .wk-button-full
  [wk-badge] {
    background-color: #fff;
    border-radius: 1rem;
    color: #007ac3;
    font-size: 0.77248em;
    font-weight: 700;
    padding: 0.125rem 0.5rem;
    position: relative;
    text-align: center;
    top: -.1em;
    white-space: nowrap; }
  .wk-badge.wk-badge-grey, .wk-badge[wk-badge*='grey'],
  [wk-badge].wk-badge-grey,
  [wk-badge][wk-badge*='grey'] {
    background-color: #bfbfbf;
    border-radius: 1rem;
    color: #fff;
    font-size: 0.77248em;
    font-weight: 700;
    padding: 0.125rem 0.5rem;
    position: relative;
    text-align: center;
    top: -.1em;
    white-space: nowrap; }

.wk-layout-50-50 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-50-50:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-50-50 > .wk-layout-item {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-50-50 > .wk-layout-item:nth-child(2n) {
        float: right;
        margin-right: 0; } }

.wk-layout-50-50-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-50-50-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-50-50-persist > .wk-layout-item {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-50-50-persist > .wk-layout-item:nth-child(2n) {
      float: right;
      margin-right: 0; }

.wk-layout-33-66 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-66:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-33-66 > .wk-layout-item {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-33-66 > .wk-layout-item:nth-child(2n) {
        width: 66.10169%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-33-66-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-66-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-33-66-persist > .wk-layout-item {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-33-66-persist > .wk-layout-item:nth-child(2n) {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-66-33 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-66-33:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-66-33 > .wk-layout-item {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-66-33 > .wk-layout-item:nth-child(2n) {
        width: 32.20339%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-66-33-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-66-33-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-66-33-persist > .wk-layout-item {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-66-33-persist > .wk-layout-item:nth-child(2n) {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-25-75 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-75:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-25-75 > .wk-layout-item {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-25-75 > .wk-layout-item:nth-child(2n) {
        width: 74.57627%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-25-75-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-75-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-25-75-persist > .wk-layout-item {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-25-75-persist > .wk-layout-item:nth-child(2n) {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-75-25 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-75-25:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-75-25 > .wk-layout-item {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-75-25 > .wk-layout-item:nth-child(2n) {
        width: 23.72881%;
        float: left;
        margin-right: 1.69492%;
        float: right;
        margin-right: 0; } }

.wk-layout-75-25-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-75-25-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-75-25-persist > .wk-layout-item {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-75-25-persist > .wk-layout-item:nth-child(2n) {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; }

.wk-layout-33-33-33 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-33-33:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-33-33-33 > .wk-layout-item {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-33-33-33 > .wk-layout-item:nth-child(3n) {
        float: right;
        margin-right: 0; } }

.wk-layout-33-33-33-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-33-33-33-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-33-33-33-persist > .wk-layout-item {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-33-33-33-persist > .wk-layout-item:nth-child(3n) {
      float: right;
      margin-right: 0; }

.wk-layout-25-25-25-25 {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-25-25-25:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-25-25-25-25 > .wk-layout-item {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
      .wk-layout-25-25-25-25 > .wk-layout-item:nth-child(4n) {
        float: right;
        margin-right: 0; } }

.wk-layout-25-25-25-25-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-25-25-25-25-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-layout-25-25-25-25-persist > .wk-layout-item {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .wk-layout-25-25-25-25-persist > .wk-layout-item:nth-child(4n) {
      float: right;
      margin-right: 0; }

.wk-layout-push-25 {
  margin-left: 25.42373%; }

.wk-layout-push-33 {
  margin-left: 33.89831%; }

.wk-layout-centered-small {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-centered-small:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-layout-centered-small {
      max-width: 28.125rem; } }

.wk-layout-centered-medium {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem; }
  .wk-layout-centered-medium:after {
    content: " ";
    display: block;
    clear: both; }

.wk-layout-centered-large {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-layout-centered-large:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) and (max-width: 65rem) {
    .wk-layout-centered-large {
      max-width: 37.5rem; } }
  @media (min-width: 65rem) {
    .wk-layout-centered-large {
      max-width: 65rem; } }

input[type='radio'],
input[type='checkbox'],
.wk-checkbox,
.wk-radio {
  font-size: 1rem;
  margin-right: 0.3125em; }

input:not([type='radio']):not([type='checkbox']),
.wk-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0;
  color: #474747;
  font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6875rem 1rem;
  width: 100%; }
  input:not([type='radio']):not([type='checkbox'])[disabled],
  .wk-input[disabled] {
    background-color: #ededed;
    color: #a3a3a3;
    cursor: not-allowed; }
  input:not([type='radio']):not([type='checkbox']):focus, input:not([type='radio']):not([type='checkbox']):hover:focus,
  .wk-input:focus,
  .wk-input:hover:focus {
    border-color: #409bd2; }
  input:not([type='radio']):not([type='checkbox']):hover,
  .wk-input:hover {
    border-color: #474747; }
  input:not([type='radio']):not([type='checkbox'])::-ms-clear,
  .wk-input::-ms-clear {
    height: 0;
    width: 0; }

:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::-webkit-input-placeholder {
  color: #bfbfbf; }

textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0;
  color: #474747;
  font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6875rem 1rem;
  width: 100%;
  display: block;
  min-height: 5em;
  resize: vertical; }
  textarea[disabled] {
    background-color: #ededed;
    color: #a3a3a3;
    cursor: not-allowed; }
  textarea:focus, textarea:hover:focus {
    border-color: #409bd2; }
  textarea:hover {
    border-color: #474747; }
  textarea::-ms-clear {
    height: 0;
    width: 0; }

.wk-textarea-short {
  min-height: 5em; }

.wk-textarea-medium {
  min-height: 8em; }

.wk-textarea-tall {
  min-height: 12em; }

.wk-textarea-no-resize {
  resize: none; }

.ie9 .wk-select-field::after {
  content: ''; }

.ie9 .wk-select-field > select {
  padding: 0; }

html.no-smil.no-borderimage .wk-select-field::after {
  display: none; }

@media (min-width: 37.5rem) {
  html.no-smil.no-borderimage .wk-select-field > select {
    padding-right: 0.4375rem; } }

html.no-smil.no-borderimage .wk-select-field > select::-ms-expand {
  display: block; }

.wk-select-field {
  position: relative; }
  .wk-select-field::after {
    color: #474747;
    content: "";
    font-family: "wk-icons-open";
    pointer-events: none;
    position: absolute;
    right: .5em;
    top: .8em;
    z-index: 1049; }
  .wk-select-field > select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fff;
    border: 1px solid #dadada;
    border-radius: 0;
    color: #474747;
    font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 1rem;
    height: 3rem;
    line-height: 1.5;
    padding: 0.6875rem 1rem;
    width: 100%;
    display: block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    overflow: hidden;
    padding-right: 1.75rem;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .wk-select-field > select[disabled] {
      background-color: #ededed;
      color: #a3a3a3;
      cursor: not-allowed; }
    .wk-select-field > select:focus, .wk-select-field > select:hover:focus {
      border-color: #409bd2; }
    .wk-select-field > select:hover {
      border-color: #474747; }
    .wk-select-field > select::-ms-clear {
      height: 0;
      width: 0; }
    .wk-select-field > select::-ms-expand {
      display: none; }
    .wk-select-field > select > option {
      overflow: hidden;
      text-overflow: ellipsis; }
    .wk-select-field > select:focus {
      border-color: #474747; }

.wk-checkbox-field[disabled],
.wk-radio-field[disabled] {
  color: #a3a3a3;
  cursor: not-allowed; }

fieldset[disabled] input:not([type='radio']):not([type='checkbox']),
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'],
fieldset[disabled] textarea,
fieldset[disabled] select,
fieldset[disabled] .wk-textarea,
fieldset[disabled] .wk-input {
  background-color: #ededed; }

fieldset[disabled] input:not([type='radio']):not([type='checkbox']),
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'],
fieldset[disabled] textarea,
fieldset[disabled] select,
fieldset[disabled] .wk-textarea,
fieldset[disabled] .wk-input,
fieldset[disabled] .wk-checkbox-field,
fieldset[disabled] .wk-radio-field {
  color: #a3a3a3;
  cursor: not-allowed; }

label {
  font-weight: 400; }

.wk-field-item {
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  margin-bottom: 1rem; }
  .wk-field-item textarea,
  .wk-field-item input:not([type='radio']):not([type='checkbox']),
  .wk-field-item .wk-textarea,
  .wk-field-item .wk-select-field,
  .wk-field-item .wk-radio-field,
  .wk-field-item .wk-checkbox-field,
  .wk-field-item .wk-input {
    font-weight: 300;
    margin-bottom: 0.1875rem; }
  .wk-field-item .wk-field-header {
    font-weight: 400;
    margin-bottom: 0.1875rem; }
    .wk-field-item .wk-field-header > .wk-field-tip {
      color: #a3a3a3;
      float: right;
      font-size: 0.87891rem;
      font-style: italic;
      margin-left: 0.375rem;
      margin-top: .1em; }
  .wk-field-item.wk-field-has-error textarea,
  .wk-field-item.wk-field-has-error input:not([type='radio']):not([type='checkbox']),
  .wk-field-item.wk-field-has-error .wk-select-field > select,
  .wk-field-item.wk-field-has-error .wk-textarea,
  .wk-field-item.wk-field-has-error .wk-input {
    border-color: #e5202e;
    color: #e5202e; }
  .wk-field-item.wk-field-has-error .wk-field-error {
    visibility: visible; }
  .wk-field-item.wk-field-has-error .wk-field-has-icon .wk-field-icon {
    color: #e5202e; }

.wk-field-has-icon {
  position: relative; }
  .wk-field-has-icon input:not([type='radio']):not([type='checkbox']),
  .wk-field-has-icon .wk-input {
    padding-right: 3.1875rem; }
  .wk-field-has-icon .wk-field-icon {
    background-color: transparent;
    height: 100%;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 1049; }
    .wk-field-has-icon .wk-field-icon [wk-icon] {
      transition: color 0.1s ease-out;
      color: #a3a3a3;
      font-size: 1.2em; }
    .wk-field-has-icon .wk-field-icon:hover {
      background-color: transparent; }
    .wk-field-has-icon .wk-field-icon.wk-field-icon-button:hover [wk-icon] {
      color: #474747; }
    .wk-field-has-icon .wk-field-icon.wk-field-icon-button:focus [wk-icon] {
      color: #474747; }

.wk-field-error {
  color: #e5202e;
  font-size: 0.87891rem;
  font-style: italic;
  visibility: hidden; }
  .wk-field-error::after {
    content: '.';
    visibility: hidden; }

@font-face {
  font-family: "Fira Sans";
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Light/FiraSans-Light.woff") format("woff"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-LightItalic/FiraSans-LightItalic.woff") format("woff"); }

@font-face {
  font-family: "Fira Sans";
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Regular/FiraSans-Regular.woff") format("woff"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Italic/FiraSans-Italic.woff") format("woff"); }

@font-face {
  font-family: "Fira Sans";
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Medium/FiraSans-Medium.woff") format("woff"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-MediumItalic/FiraSans-MediumItalic.woff") format("woff"); }

@font-face {
  font-family: "Fira Sans";
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-Bold/FiraSans-Bold.woff") format("woff"); }

@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FiraSans-BoldItalic/FiraSans-BoldItalic.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLight/FFFranziskaWebProLight.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProLightItalic/FFFranziskaWebProLightItalic.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMedium/FFFranziskaWebProMedium.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 500;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProMediumItalic/FFFranziskaWebProMediumItalic.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebPro/FFFranziskaWebPro.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBookItalic/FFFranziskaWebProBookItalic.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-weight: 600;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBold/FFFranziskaWebProDemiBold.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 600;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProDemiBoldItalic/FFFranziskaWebProDemiBoldItalic.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBold/FFFranziskaWebProBold.woff") format("woff"); }

@font-face {
  font-family: "Franziska";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/FFFranziskaWebProBoldItalic/FFFranziskaWebProBoldItalic.woff") format("woff"); }

@font-face {
  font-family: "DejaVu Sans Mono";
  font-weight: 400;
  src: url("https://cdn.wolterskluwer.io/wk-fonts/1.x.x/fonts/DejaVuSansMono-Regular/DejaVuSansMono-Regular.woff") format("woff"); }

button,
.wk-button,
[wk-button] {
  transition: background-color 0.1s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #007ac3;
  border: 0;
  border-radius: 0;
  color: #fff;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 0.87891rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 1rem;
  padding: 1.0625rem 0.9375rem;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  width: 100%; }
  button.wk-context-info,
  .wk-context-info.wk-button,
  .wk-context-info[wk-button], button[wk-context*='info'],
  [wk-context*='info'].wk-button,
  [wk-context*='info'][wk-button] {
    background-color: #007ac3;
    color: #fff; }
  button.wk-context-success,
  .wk-context-success.wk-button,
  .wk-context-success[wk-button], button[wk-context*='success'],
  [wk-context*='success'].wk-button,
  [wk-context*='success'][wk-button] {
    background-color: #85bc20;
    color: #fff; }
  button.wk-context-danger,
  .wk-context-danger.wk-button,
  .wk-context-danger[wk-button], button[wk-context*='danger'],
  [wk-context*='danger'].wk-button,
  [wk-context*='danger'][wk-button] {
    background-color: #e5202e;
    color: #fff; }
  button.wk-context-warning,
  .wk-context-warning.wk-button,
  .wk-context-warning[wk-button], button[wk-context*='warning'],
  [wk-context*='warning'].wk-button,
  [wk-context*='warning'][wk-button] {
    background-color: #ea8f00;
    color: #fff; }
  @media (min-width: 37.5rem) {
    button,
    .wk-button,
    [wk-button] {
      display: inline-block;
      margin-bottom: 0;
      width: auto; } }
  button[wk-icon],
  [wk-icon].wk-button,
  [wk-icon][wk-button] {
    speak: normal;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
    button[wk-icon]::before,
    [wk-icon].wk-button::before,
    [wk-icon][wk-button]::before {
      speak: none; }
    @media (min-width: 37.5rem) {
      button[wk-icon],
      [wk-icon].wk-button,
      [wk-icon][wk-button] {
        width: auto; } }
  button:hover:not([disabled]),
  .wk-button:hover:not([disabled]),
  [wk-button]:hover:not([disabled]) {
    background-color: #409bd2;
    color: #fff; }
  button .wk-button-icon-right,
  .wk-button .wk-button-icon-right,
  [wk-button] .wk-button-icon-right, button[wk-button*='icon-right'],
  [wk-button*='icon-right'].wk-button,
  [wk-button*='icon-right'][wk-button] {
    position: relative;
    padding-right: 4rem; }
    button .wk-button-icon-right::before,
    .wk-button .wk-button-icon-right::before,
    [wk-button] .wk-button-icon-right::before, button[wk-button*='icon-right']::before,
    [wk-button*='icon-right'].wk-button::before,
    [wk-button*='icon-right'][wk-button]::before {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      margin: 0;
      padding: 1.0625rem;
      position: absolute;
      top: 0;
      width: 3rem;
      right: 0; }
  button .wk-button-icon-left,
  .wk-button .wk-button-icon-left,
  [wk-button] .wk-button-icon-left, button[wk-button*='icon-left'],
  [wk-button*='icon-left'].wk-button,
  [wk-button*='icon-left'][wk-button] {
    position: relative;
    padding-left: 4rem; }
    button .wk-button-icon-left::before,
    .wk-button .wk-button-icon-left::before,
    [wk-button] .wk-button-icon-left::before, button[wk-button*='icon-left']::before,
    [wk-button*='icon-left'].wk-button::before,
    [wk-button*='icon-left'][wk-button]::before {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      margin: 0;
      padding: 1.0625rem;
      position: absolute;
      top: 0;
      width: 3rem;
      left: 0; }
  button.wk-button-secondary,
  .wk-button-secondary.wk-button,
  .wk-button-secondary[wk-button], button[wk-button*='secondary'],
  [wk-button*='secondary'].wk-button,
  [wk-button*='secondary'][wk-button] {
    background-color: #bfbfbf;
    color: #474747; }
    button.wk-button-secondary:hover:not([disabled]),
    .wk-button-secondary.wk-button:hover:not([disabled]),
    .wk-button-secondary[wk-button]:hover:not([disabled]), button[wk-button*='secondary']:hover:not([disabled]),
    [wk-button*='secondary'].wk-button:hover:not([disabled]),
    [wk-button*='secondary'][wk-button]:hover:not([disabled]) {
      background-color: #dadada;
      color: #474747; }
  button.wk-button-success,
  .wk-button-success.wk-button,
  .wk-button-success[wk-button], button[wk-context='success'],
  [wk-context='success'].wk-button,
  [wk-context='success'][wk-button] {
    background-color: #85bc20;
    color: #fff; }
    button.wk-button-success:hover:not([disabled]),
    .wk-button-success.wk-button:hover:not([disabled]),
    .wk-button-success[wk-button]:hover:not([disabled]), button[wk-context='success']:hover:not([disabled]),
    [wk-context='success'].wk-button:hover:not([disabled]),
    [wk-context='success'][wk-button]:hover:not([disabled]) {
      background-color: #a4cd58; }
  button.wk-button-danger,
  .wk-button-danger.wk-button,
  .wk-button-danger[wk-button], button[wk-context='danger'],
  [wk-context='danger'].wk-button,
  [wk-context='danger'][wk-button] {
    background-color: #e5202e;
    color: #fff; }
    button.wk-button-danger:hover:not([disabled]),
    .wk-button-danger.wk-button:hover:not([disabled]),
    .wk-button-danger[wk-button]:hover:not([disabled]), button[wk-context='danger']:hover:not([disabled]),
    [wk-context='danger'].wk-button:hover:not([disabled]),
    [wk-context='danger'][wk-button]:hover:not([disabled]) {
      background-color: #ec5862; }
  button.wk-button-warning,
  .wk-button-warning.wk-button,
  .wk-button-warning[wk-button], button[wk-context='warning'],
  [wk-context='warning'].wk-button,
  [wk-context='warning'][wk-button] {
    background-color: #ea8f00;
    color: #fff; }
    button.wk-button-warning:hover:not([disabled]),
    .wk-button-warning.wk-button:hover:not([disabled]),
    .wk-button-warning[wk-button]:hover:not([disabled]), button[wk-context='warning']:hover:not([disabled]),
    [wk-context='warning'].wk-button:hover:not([disabled]),
    [wk-context='warning'][wk-button]:hover:not([disabled]) {
      background-color: #efab40; }
  button[disabled],
  [disabled].wk-button,
  [disabled][wk-button] {
    opacity: 0.3; }
  button.wk-button-full,
  .wk-button-full.wk-button,
  .wk-button-full[wk-button], button[wk-button*='full'],
  [wk-button*='full'].wk-button,
  [wk-button*='full'][wk-button] {
    width: 100%; }

a.wk-button,
a[wk-button] {
  display: block;
  text-align: center; }
  @media (min-width: 37.5rem) {
    a.wk-button,
    a[wk-button] {
      display: inline-block; } }
  a.wk-button:hover,
  a[wk-button]:hover {
    color: #fff; }
  a.wk-button .wk-button-link,
  a[wk-button] .wk-button-link, a[wk-button*='link'].wk-button,
  a[wk-button*='link'][wk-button] {
    position: relative;
    padding-left: 4rem;
    background-color: transparent;
    color: #474747;
    margin-bottom: 0;
    padding: 0;
    padding-left: 1.25em; }
    a.wk-button .wk-button-link::before,
    a[wk-button] .wk-button-link::before, a[wk-button*='link'].wk-button::before,
    a[wk-button*='link'][wk-button]::before {
      background: transparent;
      color: #fff;
      margin: 0;
      padding: 1.0625rem;
      position: absolute;
      top: 0;
      width: 3rem;
      left: 0; }
    a.wk-button .wk-button-link:hover,
    a[wk-button] .wk-button-link:hover, a[wk-button*='link'].wk-button:hover,
    a[wk-button*='link'][wk-button]:hover {
      background-color: transparent;
      color: #474747;
      text-decoration: underline; }
    a.wk-button .wk-button-link::before,
    a[wk-button] .wk-button-link::before, a[wk-button*='link'].wk-button::before,
    a[wk-button*='link'][wk-button]::before {
      color: #474747;
      padding: 0;
      text-decoration: none;
      width: 3px; }

.wk-button-bar {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-button-bar:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-button-bar .wk-button-group-left {
      float: left; } }
  .wk-button-bar .wk-button-group-left + .wk-button-group-right {
    margin-top: 1rem; }
    @media (min-width: 37.5rem) {
      .wk-button-bar .wk-button-group-left + .wk-button-group-right {
        margin-top: 0; } }
  @media (min-width: 37.5rem) {
    .wk-button-bar .wk-button-group-right {
      float: right; } }
  @media (min-width: 37.5rem) {
    .wk-button-bar button, .wk-button-bar .wk-button, .wk-button-bar [wk-button] {
      float: left;
      margin-bottom: 0;
      margin-right: 1rem; }
      .wk-button-bar button:last-child, .wk-button-bar .wk-button:last-child, .wk-button-bar [wk-button]:last-child {
        margin-right: 0; } }
  .wk-button-bar button:last-child, .wk-button-bar .wk-button:last-child, .wk-button-bar [wk-button]:last-child {
    margin-bottom: 0; }

.wk-hide-text {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0 / 0 a;
  text-shadow: none; }

h1.wk-hide-text,
h2.wk-hide-text,
h3.wk-hide-text,
h4.wk-hide-text,
h5.wk-hide-text,
h6.wk-hide-text {
  margin: 0; }

.wk-clearfix::after {
  clear: both;
  content: ' ';
  display: table; }

.wk-text-overflow {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.wk-shown {
  display: block; }

.wk-hidden {
  display: none; }

@media (min-width: 37.5rem) {
  .wk-logo-small {
    display: none; } }

.wk-logo-large {
  display: none; }
  @media (min-width: 37.5rem) {
    .wk-logo-large {
      display: block; } }

.wk-img-responsive {
  height: auto;
  max-width: 100%; }

.wk-unstyled-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.wk-fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999; }

.wk-header {
  background-color: #fff;
  width: 100%; }
  .wk-header > .wk-header-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    .wk-header > .wk-header-container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) {
      .wk-header > .wk-header-container {
        padding: 0.5625rem 1rem; } }
    @media (min-width: 77rem) {
      .wk-header > .wk-header-container {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0.5625rem; } }
  .wk-header .wk-logo {
    display: block;
    float: left;
    margin: 0.78125rem 0.5rem 0.8125rem 1rem; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-logo {
        margin: 1.46875rem 0; } }
  .wk-header .wk-nav > li,
  .wk-header .wk-more-menu > li {
    display: block; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav > li,
      .wk-header .wk-more-menu > li {
        padding: 0; } }
    .wk-header .wk-nav > li [wk-icon],
    .wk-header .wk-more-menu > li [wk-icon] {
      margin-right: 0.5em; }
    .wk-header .wk-nav > li:last-child,
    .wk-header .wk-more-menu > li:last-child {
      margin-right: 0; }
  .wk-header .wk-nav.wk-green-icons > li [wk-icon],
  .wk-header .wk-more-menu.wk-green-icons > li [wk-icon] {
    color: #85bc20; }
  .wk-header .wk-nav.wk-gray-icons > li [wk-icon],
  .wk-header .wk-more-menu.wk-gray-icons > li [wk-icon] {
    color: #757575; }
  .wk-header .wk-nav.wk-gray-icons > li > a:hover [wk-icon],
  .wk-header .wk-more-menu.wk-gray-icons > li > a:hover [wk-icon] {
    color: #85bc20;
    transition: color .3s; }
  .wk-header .wk-nav {
    display: none;
    background-color: #474747;
    color: #fff;
    float: left;
    width: 100%; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav {
        display: block;
        background-color: transparent;
        float: right;
        margin-top: 1.1875rem;
        width: auto; } }
    @media (min-width: 77rem) {
      .wk-header .wk-nav {
        padding: 0; } }
    .wk-header .wk-nav > li:last-child {
      padding-right: 0; }
    .wk-header .wk-nav > li > a {
      padding: 0.625rem 0.9375rem; }
    .wk-header .wk-nav > li > a,
    .wk-header .wk-nav > li > a:hover {
      color: #fff; }
      @media (min-width: 37.5rem) {
        .wk-header .wk-nav > li > a,
        .wk-header .wk-nav > li > a:hover {
          color: #757575; } }
    .wk-header .wk-nav > li > a:hover {
      color: #85bc20; }
    .wk-header .wk-nav.open {
      display: block;
      background-color: rgba(71, 71, 71, 0.95);
      position: absolute;
      top: 2.9375rem;
      z-index: 1040; }
      @media (min-width: 37.5rem) {
        .wk-header .wk-nav.open {
          background-color: transparent;
          position: relative;
          top: 0; } }
      .wk-header .wk-nav.open [wk-icon] {
        color: #fff; }
  .wk-header .wk-more-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    float: right;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif; }
    .wk-header .wk-more-menu > li {
      display: block; }
      .wk-header .wk-more-menu > li > a {
        display: block; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-more-menu {
        display: none; } }
    .wk-header .wk-more-menu a {
      color: #757575;
      min-height: 47px;
      padding: 0.75rem 1rem 0.6875rem; }
    .wk-header .wk-more-menu a:hover {
      color: #85bc20; }
    .wk-header .wk-more-menu.open {
      background-color: rgba(71, 71, 71, 0.95); }
      .wk-header .wk-more-menu.open a {
        color: #fff; }
      .wk-header .wk-more-menu.open a:hover {
        color: #85bc20; }

.wk-footer {
  background-color: #757575;
  margin-top: 1.75rem; }
  .wk-footer .wk-footer-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.875rem;
    padding-top: 1.875rem; }
    .wk-footer .wk-footer-container:after {
      content: " ";
      display: block;
      clear: both; }
    .wk-footer .wk-footer-container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-footer .wk-footer-container {
        padding-left: 0;
        padding-right: 0; } }
  .wk-footer .wk-footer-nav::after {
    clear: both;
    content: ' ';
    display: table; }
  @media (min-width: 37.5rem) {
    .wk-footer .wk-footer-nav {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem;
      width: auto; }
      .wk-footer .wk-footer-nav:after {
        content: " ";
        display: block;
        clear: both; } }
  @media (min-width: 37.5rem) and (min-width: 37.5rem) {
    .wk-footer .wk-footer-nav > .wk-footer-nav-list {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
      .wk-footer .wk-footer-nav > .wk-footer-nav-list:nth-child(4n) {
        float: right;
        margin-right: 0; } }
  .wk-footer .wk-footer-nav .wk-footer-nav-list {
    margin-bottom: 2.5rem; }
    @media (min-width: 37.5rem) {
      .wk-footer .wk-footer-nav .wk-footer-nav-list {
        margin-bottom: 0; } }
    .wk-footer .wk-footer-nav .wk-footer-nav-list .wk-footer-heading {
      color: #bfbfbf;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 1rem;
      margin-top: 0; }
      @media (min-width: 37.5rem) {
        .wk-footer .wk-footer-nav .wk-footer-nav-list .wk-footer-heading {
          font-size: 0.87891rem;
          margin-bottom: 0.5rem; } }
    .wk-footer .wk-footer-nav .wk-footer-nav-list > ul {
      list-style: none;
      padding: 0; }
      .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li {
        color: #fff;
        font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem; }
        @media (min-width: 37.5rem) {
          .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li {
            font-size: 0.87891rem;
            margin-bottom: 0.5rem; } }
        .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li:last-child {
          margin-bottom: 0; }
        .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li > a {
          color: #fff;
          display: block; }
          @media (min-width: 65rem) {
            .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li > a {
              display: inline; } }
          .wk-footer .wk-footer-nav .wk-footer-nav-list > ul > li > a:hover {
            color: #ededed; }
  @media (min-width: 65rem) {
    .wk-footer .wk-logo {
      float: left; } }
  .wk-footer .wk-tagline {
    color: #fff;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.87891rem;
    font-weight: 300;
    margin-top: 1.75rem; }
    @media (min-width: 65rem) {
      .wk-footer .wk-tagline {
        float: right;
        font-size: 1.13778rem;
        margin-top: 0;
        position: relative;
        top: 7px; } }
  .wk-footer .wk-social-icons-container li {
    display: inline-block; }
  .wk-footer .wk-social-icons-container .wk-social-item {
    font-size: 1.75rem;
    margin-right: 0.625rem; }
    .wk-footer .wk-social-icons-container .wk-social-item span {
      height: 1px;
      overflow: hidden;
      position: absolute;
      top: -10px;
      width: 1px; }
  .wk-footer .wk-footer-copyright {
    background-color: #f6f6f6;
    color: #757575;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.82397rem; }
    .wk-footer .wk-footer-copyright .wk-footer-copyright-container {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1.25rem;
      padding-top: 1.25rem; }
      .wk-footer .wk-footer-copyright .wk-footer-copyright-container:after {
        content: " ";
        display: block;
        clear: both; }
      .wk-footer .wk-footer-copyright .wk-footer-copyright-container:after {
        content: " ";
        display: block;
        clear: both; }
      @media (min-width: 77rem) {
        .wk-footer .wk-footer-copyright .wk-footer-copyright-container {
          padding-left: 0;
          padding-right: 0; } }
  .wk-footer .wk-footer-meta-info {
    padding-left: 0; }
    .wk-footer .wk-footer-meta-info li {
      display: inline;
      list-style-type: none;
      margin-right: 2rem;
      padding-left: 0; }
    .wk-footer .wk-footer-meta-info a {
      color: #757575; }
      .wk-footer .wk-footer-meta-info a:hover {
        text-decoration: underline; }

.wk-row {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-row:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) {
    .wk-row div[class^='wk-col']:last-child {
      float: right;
      margin-right: 0; }
    .wk-row .wk-col-1 {
      width: 6.77966%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-2 {
      width: 15.25424%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-3 {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-4 {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-5 {
      width: 40.67797%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-6 {
      width: 49.15254%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-7 {
      width: 57.62712%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-8 {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-9 {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-10 {
      width: 83.05085%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-11 {
      width: 91.52542%;
      float: left;
      margin-right: 1.69492%; }
    .wk-row .wk-col-12 {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; } }

.wk-row-persist {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-row-persist:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-row-persist div[class^='wk-col']:last-child {
    float: right;
    margin-right: 0; }
  .wk-row-persist .wk-col-1 {
    width: 6.77966%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-2 {
    width: 15.25424%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-3 {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-4 {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-5 {
    width: 40.67797%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-6 {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-7 {
    width: 57.62712%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-8 {
    width: 66.10169%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-9 {
    width: 74.57627%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-10 {
    width: 83.05085%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-11 {
    width: 91.52542%;
    float: left;
    margin-right: 1.69492%; }
  .wk-row-persist .wk-col-12 {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }

.wk-table-permit-overflow {
  border: 1px solid #ededed;
  border-bottom: 0;
  border-top: 0;
  margin-bottom: 1.25rem;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%; }
  .wk-table-permit-overflow > .wk-table {
    border: 0;
    margin-bottom: 0; }
    .wk-table-permit-overflow > .wk-table th:last-child,
    .wk-table-permit-overflow > .wk-table td:last-child {
      border-right: 0; }
    .wk-table-permit-overflow > .wk-table th:first-child,
    .wk-table-permit-overflow > .wk-table td:first-child {
      border-left: 0; }

.wk-table {
  border-collapse: collapse;
  margin-bottom: 1.25rem;
  max-width: 100%;
  width: 100%; }
  .wk-table > thead > tr > th,
  .wk-table > thead > tr > td,
  .wk-table > tfoot > tr > th,
  .wk-table > tbody > tr > td,
  .wk-table > tbody > tr > th {
    padding: 0.625rem;
    vertical-align: top; }
  .wk-table th {
    white-space: nowrap;
    text-align: left; }
  .wk-table caption {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    text-align: left; }
  .wk-table th,
  .wk-table td {
    border: 1px solid #dadada; }
  .wk-table > tfoot > tr > th,
  .wk-table > thead > tr > th {
    background-color: #f6f6f6;
    color: #474747;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.82397rem; }
  .wk-table > tbody > tr > td {
    background-color: #fff;
    border-top: #ededed;
    color: #474747;
    font-size: 0.9375rem; }
  .wk-table > tbody + tbody {
    border-top: 2px solid #dadada; }
  .wk-table .wk-table-ascent,
  .wk-table .wk-table-descent {
    background-color: #ededed; }
  .wk-table .wk-table-sortable::after,
  .wk-table .wk-table-ascent::after,
  .wk-table .wk-table-descent::after {
    display: inline-block;
    font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1em;
    margin-left: .2em;
    margin-right: .2em;
    speak: none;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    width: 1em;
    font-family: "wk-icons-filled"; }
    .wk-table .wk-table-sortable::after::before,
    .wk-table .wk-table-ascent::after::before,
    .wk-table .wk-table-descent::after::before {
      font-family: "wk-icons-filled"; }
  .wk-table .wk-table-sortable::after {
    content: ""; }
  .wk-table .wk-table-ascent::after {
    content: ""; }
  .wk-table .wk-table-descent::after {
    content: ""; }
  .wk-table .wk-table-sorted {
    background-color: #f6f6f6; }

.wk-label, .wk-label-info, .wk-label-success, .wk-label-danger {
  display: inline-block;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.3125rem 0.625rem;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap; }
  .wk-context-info.wk-label, .wk-context-info.wk-label-info, .wk-context-info.wk-label-success, .wk-context-info.wk-label-danger, [wk-context*='info'].wk-label, [wk-context*='info'].wk-label-info, [wk-context*='info'].wk-label-success, [wk-context*='info'].wk-label-danger {
    background-color: #007ac3;
    color: #fff; }
  .wk-context-success.wk-label, .wk-context-success.wk-label-info, .wk-context-success.wk-label-success, .wk-context-success.wk-label-danger, [wk-context*='success'].wk-label, [wk-context*='success'].wk-label-info, [wk-context*='success'].wk-label-success, [wk-context*='success'].wk-label-danger {
    background-color: #85bc20;
    color: #fff; }
  .wk-context-danger.wk-label, .wk-context-danger.wk-label-info, .wk-context-danger.wk-label-success, .wk-context-danger.wk-label-danger, [wk-context*='danger'].wk-label, [wk-context*='danger'].wk-label-info, [wk-context*='danger'].wk-label-success, [wk-context*='danger'].wk-label-danger {
    background-color: #e5202e;
    color: #fff; }
  .wk-context-warning.wk-label, .wk-context-warning.wk-label-info, .wk-context-warning.wk-label-success, .wk-context-warning.wk-label-danger, [wk-context*='warning'].wk-label, [wk-context*='warning'].wk-label-info, [wk-context*='warning'].wk-label-success, [wk-context*='warning'].wk-label-danger {
    background-color: #ea8f00;
    color: #fff; }

.wk-label {
  background-color: #757575;
  color: #fff; }

.wk-label-info {
  background-color: #007ac3;
  color: #fff; }

.wk-label-success {
  background-color: #85bc20;
  color: #fff; }

.wk-label-danger {
  background-color: #e5202e;
  color: #fff; }

.wk-document a:hover {
  text-decoration: underline; }

.wk-document p,
.wk-document ol,
.wk-document ul {
  color: #474747;
  font-family: "Franziska", "Times New Roman", Times, serif;
  font-size: 1.06667rem;
  line-height: 1.7; }

.wk-document ol,
.wk-document ul {
  margin: 1em 0 1em 3em;
  padding: 0; }
  .wk-document ol > li,
  .wk-document ul > li {
    margin-bottom: .5em; }

.wk-document ul {
  list-style-type: square; }

.wk-document p {
  margin-bottom: 1.25rem; }

.wk-document-legacy .dps-resource a.smallLink:link {
  color: #0000cc;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.smallLink:active {
  color: #000099; }

.wk-document-legacy .dps-resource a.smallLink:visited {
  color: #660066;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.smallLink:hover {
  color: #0000cc;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.small-link {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal; }
  .wk-document-legacy .dps-resource a.small-link:link {
    color: blue; }

.wk-document-legacy .dps-resource a.metadataLink:link {
  color: #ffffff;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.metadataLink:active {
  color: #ffffff; }

.wk-document-legacy .dps-resource a.metadataLink:visited {
  color: #ffffff;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.metadataLink:hover {
  color: #ffffff;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a.breadCrumb:link {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #0000cc;
  text-decoration: none; }

.wk-document-legacy .dps-resource a.breadCrumb:active {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #000099; }

.wk-document-legacy .dps-resource a.breadCrumb:visited {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #660066; }

.wk-document-legacy .dps-resource a.breadCrumb:hover {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #0000cc;
  text-decoration: underline; }

.wk-document-legacy .dps-resource a:empty {
  display: none; }

.wk-document-legacy .dps-resource .outer-table-wrapper {
  overflow: auto;
  margin-bottom: 1.875rem; }

.wk-document-legacy .dps-resource .inner-table-wrapper {
  overflow: visible; }

.wk-document-legacy .dps-resource div.content-wrap {
  padding: 10px;
  padding: 0; }

.wk-document-legacy .dps-resource div.tableVspace {
  width: 100%;
  height: 16px;
  border: 1px  black solid; }

.wk-document-legacy .dps-resource div.path-line-content {
  margin: 12px 0 0;
  padding: 4px 0 0; }

.wk-document-legacy .dps-resource div.tochead {
  margin-left: 0;
  margin-top: 3pt;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 120%;
  font-weight: bold; }

.wk-document-legacy .dps-resource div.toc {
  margin-left: 10pt;
  margin-top: 3pt; }

.wk-document-legacy .dps-resource div.tocindent {
  margin-left: 10pt;
  margin-bottom: 6pt; }

.wk-document-legacy .dps-resource div.space {
  display: table;
  vertical-align: bottom;
  margin: 4px 0; }

.wk-document-legacy .dps-resource div.graphic {
  width: 100%;
  text-align: center; }

.wk-document-legacy .dps-resource div.warningMsg {
  color: #cc0000;
  font-weight: bold; }

.wk-document-legacy .dps-resource div.nav-caution-note-wrapper {
  position: relative; }

.wk-document-legacy .dps-resource div.cnBubble {
  left: 5px;
  z-index: 9999999;
  position: absolute;
  min-width: 480px;
  padding: 0;
  background: #ffffbe;
  border-radius: 0;
  border: #ff8315 solid 2px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.75); }
  .wk-document-legacy .dps-resource div.cnBubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent #ffffbe;
    display: block;
    width: 0;
    z-index: 1;
    left: -8px;
    top: 10px; }
  .wk-document-legacy .dps-resource div.cnBubble:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent #ff8315;
    display: block;
    width: 0;
    z-index: 0;
    left: -11px;
    top: 9px; }

.wk-document-legacy .dps-resource div.cnContent {
  padding: 8px; }

.wk-document-legacy .dps-resource div.media-container {
  display: inline-block;
  margin: 5px 0 10px; }
  .wk-document-legacy .dps-resource div.media-container span.tooltip-hide {
    display: none; }
  .wk-document-legacy .dps-resource div.media-container span.tooltip-show {
    display: inline;
    margin-left: 20px;
    margin-top: 20px;
    position: absolute;
    border: 1px solid #cccccc;
    background: #ffffff;
    color: #6c6c6c; }

.wk-document-legacy .dps-resource div.cnBody p {
  margin: 6px 0 0;
  font-style: italic; }

.wk-document-legacy .dps-resource .docHeadSpacer {
  font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-right: 4pt; }

.wk-document-legacy .dps-resource h1.metadata {
  text-align: left;
  font-size: 120%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 0; }

.wk-document-legacy .dps-resource h1.valueadd {
  padding: 0;
  margin: 0;
  font: bold large/1em Arial, sans-serif;
  border-bottom: #660066 2px solid; }

.wk-document-legacy .dps-resource h2.valueadd {
  padding: 0;
  margin: 0;
  font: bold medium/1em Arial, sans-serif; }

.wk-document-legacy .dps-resource h3.valueadd {
  padding: 0;
  margin: 0;
  font: bold small/1em Arial, sans-serif; }

.wk-document-legacy .dps-resource table {
  width: 100%; }
  .wk-document-legacy .dps-resource table.metadata {
    background-color: transparent;
    font-size: 8pt;
    font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
    margin-top: 0; }
  .wk-document-legacy .dps-resource table.noteIndent {
    margin-left: 15px; }

.wk-document-legacy .dps-resource td.metadatahead {
  font-weight: bold;
  padding-left: 10pt;
  padding-right: 10pt;
  width: 150pt; }

.wk-document-legacy .dps-resource td.metadatahead_print {
  font-weight: bold;
  padding-left: 0;
  padding-right: 10pt;
  width: 150pt; }

.wk-document-legacy .dps-resource td.metadatahead2 {
  font-weight: bold;
  padding-right: 10pt; }

.wk-document-legacy .dps-resource td.olText {
  height: auto;
  padding-bottom: 10pt;
  padding-left: 6px; }

.wk-document-legacy .dps-resource td.digit {
  text-align: right; }

.wk-document-legacy .dps-resource td.small-cap {
  text-transform: uppercase;
  font-weight: normal; }

.wk-document-legacy .dps-resource td.bold-small-cap {
  text-transform: uppercase;
  font-weight: bold; }

.wk-document-legacy .dps-resource td.underline {
  text-decoration: underline;
  font-weight: normal; }

.wk-document-legacy .dps-resource td.roman {
  font-weight: normal; }

.wk-document-legacy .dps-resource td.bold {
  font-weight: bold; }

.wk-document-legacy .dps-resource td.italic {
  font-style: italic; }

.wk-document-legacy .dps-resource td.bold-italic {
  font-style: italic;
  font-weight: bold; }

.wk-document-legacy .dps-resource td.metricsCell {
  width: 25%;
  color: #888888;
  font-size: .85em;
  vertical-align: top; }
  .wk-document-legacy .dps-resource td.metricsCell .dateCreated {
    font-weight: bold; }
  .wk-document-legacy .dps-resource td.metricsCell .metrics .author.deleted {
    font-style: italic; }
  .wk-document-legacy .dps-resource td.metricsCell .metrics .author.disabled {
    font-style: italic; }

.wk-document-legacy .dps-resource td.notesCell {
  width: 75%; }

.wk-document-legacy .dps-resource td p.hp {
  margin: 0 0 10px 0; }

.wk-document-legacy .dps-resource p.caption {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: normal;
  font-style: italic; }

.wk-document-legacy .dps-resource p.blockquote {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  line-height: 1.25em; }

.wk-document-legacy .dps-resource p.related-topics-state {
  margin: 0 10px 0 0;
  padding: 0;
  font: bold italic small/1.3em Arial, Helvetica, sans-serif;
  color: #000080; }

.wk-document-legacy .dps-resource p.docType {
  display: block;
  padding: 0;
  font: bold small/1em Arial, sans-serif;
  color: #244680; }

.wk-document-legacy .dps-resource p:empty {
  display: none; }

.wk-document-legacy .dps-resource pre {
  font-family: "Courier New", "Courier", "monospace";
  font-size: small; }

.wk-document-legacy .dps-resource span.toc {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 80%; }

.wk-document-legacy .dps-resource span.emphasis {
  font-weight: bold; }

.wk-document-legacy .dps-resource span.numemphasis {
  font-weight: bold;
  color: maroon;
  font-size: 110%; }

.wk-document-legacy .dps-resource span.footnote {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  line-height: 1.25em; }

.wk-document-legacy .dps-resource span.leftCol {
  display: inline;
  background-color: white;
  vertical-align: bottom;
  text-align: left; }

.wk-document-legacy .dps-resource span.rightCol {
  display: table-cell;
  float: right;
  background-color: white;
  text-align: right;
  vertical-align: bottom; }

.wk-document-legacy .dps-resource span.space {
  display: inline-block;
  vertical-align: bottom;
  text-align: left; }

.wk-document-legacy .dps-resource .break {
  display: block; }

.wk-document-legacy .dps-resource ul.nested-ul {
  list-style-image: none; }

.wk-document-legacy .dps-resource ul.square {
  list-style-type: square; }

.wk-document-legacy .dps-resource ul.none {
  list-style-type: none; }

.wk-document-legacy .dps-resource ul.bull {
  list-style-type: square; }

.wk-document-legacy .dps-resource ul.bull > li {
  display: list-item !important; }

.wk-document-legacy .dps-resource li {
  margin: 0 0 10px 0; }

.wk-document-legacy .dps-resource .menupathBoxRow1 {
  width: 100%;
  height: 24px;
  text-align: right; }

.wk-document-legacy .dps-resource .menupathBoxRow2 {
  width: 100%; }

.wk-document-legacy .dps-resource .doc-path-wrapper {
  margin: 0;
  padding: 10px; }

.wk-document-legacy .dps-resource .doc_path_container {
  background-color: white;
  margin: 0;
  border: solid #00504c 1px;
  padding: 4px; }
  .wk-document-legacy .dps-resource .doc_path_container div.navigation-line div.link-box {
    text-align: center;
    margin: 10px 0 5px; }

.wk-document-legacy .dps-resource .clickpathNode {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  margin: 0 5px; }

.wk-document-legacy .dps-resource .docid {
  width: 100%;
  height: 18px;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: bold;
  background-color: #00504c;
  color: #ffffff;
  padding: 2pt; }

.wk-document-legacy .dps-resource .date {
  color: navy; }

.wk-document-legacy .dps-resource .inline {
  color: purple; }

.wk-document-legacy .dps-resource .inline2 {
  color: green; }

.wk-document-legacy .dps-resource .center {
  text-align: center; }

.wk-document-legacy .dps-resource .metadataContainer {
  display: none; }

.wk-document-legacy .dps-resource .showMetadata {
  position: absolute;
  float: right; }

.wk-document-legacy .dps-resource .docSearchTerm {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource #moreInfoBox {
  border: 1px solid #00504c;
  padding: 6px;
  width: 99%;
  -moz-box-sizing: padding-box; }

.wk-document-legacy .dps-resource .moreInfoText {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 11pt;
  color: #006699;
  text-transform: capitalize;
  white-space: nowrap; }

.wk-document-legacy .dps-resource .moreInfoBullet {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14pt;
  color: #006699;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center; }

.wk-document-legacy .dps-resource .newDevBox {
  background: #ffffee;
  padding: 10px;
  bordnr: 1px solid; }
  .wk-document-legacy .dps-resource .newDevBox h1 {
    margin-top: 0;
    border-bottom: 2px solid #660066;
    font: bold small/1.5em Arial, sans-serif;
    text-transform: uppercase; }
  .wk-document-legacy .dps-resource .newDevBox h2 {
    margin-bottom: 0; }
  .wk-document-legacy .dps-resource .newDevBox p {
    margin-top: 0; }

.wk-document-legacy .dps-resource #newDevBtn {
  width: 130px;
  height: 19px;
  padding: 2px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px; }

.wk-document-legacy .dps-resource .newDevBtnHighlighted {
  border: 1px solid #0000cc;
  background-color: #f4f3c9;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  color: #ff0000;
  font-weight: bold;
  font-size: 11px;
  text-align: center; }

.wk-document-legacy .dps-resource .quicklinks {
  margin: 4px 10px 0 15px;
  padding: 0;
  background: #fef8e3;
  border: 1px solid #ccc;
  font: small/1.3em Arial, Helvetica, sans-serif; }
  .wk-document-legacy .dps-resource .quicklinks p {
    margin: 0 12px;
    padding: 0 0 12px 0; }
  .wk-document-legacy .dps-resource .quicklinks h1, .wk-document-legacy .dps-resource .quicklinks h2, .wk-document-legacy .dps-resource .quicklinks h3 {
    background-color: #003366;
    margin: 0 -1px 7px;
    padding: 4px 10px;
    color: #eee;
    font: bold small/1.3em Arial, Helvetica, sans-serif; }
  .wk-document-legacy .dps-resource .quicklinks li {
    margin: 0;
    padding: 0 0 0 2px;
    line-height: 1.1em; }
  .wk-document-legacy .dps-resource .quicklinks ul {
    margin-left: 12px; }

.wk-document-legacy .dps-resource .deleted {
  font: bold small/1.3em Arial, Helvetica, sans-serif;
  color: #ff0000; }

.wk-document-legacy .dps-resource .vetoed {
  font: bold small/1.3em Arial, Helvetica, sans-serif;
  color: #0000ee; }

.wk-document-legacy .dps-resource .added {
  font: bold italic small/1.3em Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .addedgreen {
  font: bold italic small/1.3em Arial, Helvetica, sans-serif;
  color: #008000; }

.wk-document-legacy .dps-resource #learningCenter {
  height: 178px;
  width: 596px;
  margin-top: 20px; }

.wk-document-legacy .dps-resource #LCloginBtn {
  position: relative;
  left: 470px;
  top: 140px; }

.wk-document-legacy .dps-resource .breadCrumb {
  padding: 8px 0; }

.wk-document-legacy .dps-resource #smartRelate {
  padding: 0 0 35px 0;
  margin: 0 3px 10px 6px; }

.wk-document-legacy .dps-resource .noteContainer {
  padding-left: 25px;
  margin: 15px 0; }

.wk-document-legacy .dps-resource .noteBody {
  font-family: "Franziska", "Times New Roman", Times, serif; }

.wk-document-legacy .dps-resource .noteHead {
  font-weight: bold;
  margin: 0 0 0 15px; }

.wk-document-legacy .dps-resource .noteDisplayLabel,
.wk-document-legacy .dps-resource .note,
.wk-document-legacy .dps-resource .caution-note,
.wk-document-legacy .dps-resource .planning-note,
.wk-document-legacy .dps-resource .example,
.wk-document-legacy .dps-resource .faq,
.wk-document-legacy .dps-resource .comment,
.wk-document-legacy .dps-resource .cch-comment,
.wk-document-legacy .dps-resource .department-comment,
.wk-document-legacy .dps-resource .practitioner-comment,
.wk-document-legacy .dps-resource .checklist,
.wk-document-legacy .dps-resource .key-concepts,
.wk-document-legacy .dps-resource .tools,
.wk-document-legacy .dps-resource .gray-area,
.wk-document-legacy .dps-resource .cch-pointer,
.wk-document-legacy .dps-resource .cch-practice-tip,
.wk-document-legacy .dps-resource .compliance-tip,
.wk-document-legacy .dps-resource .compliance-pointer,
.wk-document-legacy .dps-resource .exam-standard,
.wk-document-legacy .dps-resource .expert,
.wk-document-legacy .dps-resource .forms,
.wk-document-legacy .dps-resource .legal,
.wk-document-legacy .dps-resource .policy,
.wk-document-legacy .dps-resource .sample-plan,
.wk-document-legacy .dps-resource .best-practice,
.wk-document-legacy .dps-resource .background,
.wk-document-legacy .dps-resource .summary,
.wk-document-legacy .dps-resource .iResearchAids,
.wk-document-legacy .dps-resource .cch-chart {
  color: #474747;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 6px;
  position: relative; }
  .wk-document-legacy .dps-resource .noteDisplayLabel:before,
  .wk-document-legacy .dps-resource .note:before,
  .wk-document-legacy .dps-resource .caution-note:before,
  .wk-document-legacy .dps-resource .planning-note:before,
  .wk-document-legacy .dps-resource .example:before,
  .wk-document-legacy .dps-resource .faq:before,
  .wk-document-legacy .dps-resource .comment:before,
  .wk-document-legacy .dps-resource .cch-comment:before,
  .wk-document-legacy .dps-resource .department-comment:before,
  .wk-document-legacy .dps-resource .practitioner-comment:before,
  .wk-document-legacy .dps-resource .checklist:before,
  .wk-document-legacy .dps-resource .key-concepts:before,
  .wk-document-legacy .dps-resource .tools:before,
  .wk-document-legacy .dps-resource .gray-area:before,
  .wk-document-legacy .dps-resource .cch-pointer:before,
  .wk-document-legacy .dps-resource .cch-practice-tip:before,
  .wk-document-legacy .dps-resource .compliance-tip:before,
  .wk-document-legacy .dps-resource .compliance-pointer:before,
  .wk-document-legacy .dps-resource .exam-standard:before,
  .wk-document-legacy .dps-resource .expert:before,
  .wk-document-legacy .dps-resource .forms:before,
  .wk-document-legacy .dps-resource .legal:before,
  .wk-document-legacy .dps-resource .policy:before,
  .wk-document-legacy .dps-resource .sample-plan:before,
  .wk-document-legacy .dps-resource .best-practice:before,
  .wk-document-legacy .dps-resource .background:before,
  .wk-document-legacy .dps-resource .summary:before,
  .wk-document-legacy .dps-resource .iResearchAids:before,
  .wk-document-legacy .dps-resource .cch-chart:before {
    background-repeat: no-repeat;
    background-position: 0 0;
    content: '';
    height: 16px;
    left: -25px;
    position: absolute;
    top: 3px;
    width: 16px; }

.wk-document-legacy .dps-resource .caution-note:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQcALvi///sqP/nnd7w///tsd7x/9fu/+n1/+n2/9Ls///tt8Dk/8Dj/+Pz/9Hr/7vh///qov/daprN87u7u//jk2OQsXSs1uTz/5kAAMwAAP///5lmM////wAAAAAAAAAAACH5BAEAABwALAAAAAAQABAAAAV4IMdZZGmS4nit67axa6VqtLZp1MtKlXXVNpxuxfPVbhlMbtf70ZCYyAZxOBRZN0UyAnk1roXCDbLtDgZXwzhQ7hquiRshABEIKDnHVU7f4nN7OHgRhH8bVwsuiosbDFcPAJEAD5SQlBMyFhObnJ2bMiIVoqOkoiIhADs=); }

.wk-document-legacy .dps-resource .noteDisplayLabel:before,
.wk-document-legacy .dps-resource .note:before,
.wk-document-legacy .dps-resource .compliance-tip:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQbALvi/8Dj/7vh/9fu/8Dk/8bm/9ju/9Ls/+nv4d7w/97x///ff9Hr///44en1/6HO6ufw7Pe+W20/Ef/ShJrN82OQsXSs1uTz//+ZM5lmM////////wAAAAAAAAAAAAAAACH5BAEAABsALAAAAAAQABAAAAV74LZZZGmS4nitbLtWqiZrWZ21VGVdzZxpkdsKkdvJfr/gRVi80DCSZE0jcTQlmgl0EsFqJU2Fd0ImQxPNwWBslhgGRcjhVy5DGQ/dUrOAAr1QTQUSUFJYEgVNBAQSAVIBjQFNAgCVNhmUAgI5IxSen6CeMCIVpaanpSIhADs=); }

.wk-document-legacy .dps-resource .planning-note:before,
.wk-document-legacy .dps-resource .compliance-pointer:before,
.wk-document-legacy .dps-resource .summary:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQALMNAL1IAvvsx//jk//wxP/dam0/EcVrI5rN82OQsXSs1uTz/////5lmM////wAAAAAAACH5BAEAAA0ALAAAAAAQABAAAARfsLVEq6Vyqs37RhojjuR2IInCLGy7rIxyputg3zUzqwswGEBDYVjYrQK35KCI4gEEwaCASVsIrlgs1QmNGqbGBWFMJm9XAILXQDiLy+UzcT4/TQ74vB4PkiD+gIF/EhEAOw==); }

.wk-document-legacy .dps-resource .comment:before,
.wk-document-legacy .dps-resource .cch-comment:before,
.wk-document-legacy .dps-resource .department-comment:before,
.wk-document-legacy .dps-resource .practitioner-comment:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQTAMDj/7vh/7vi/8Dk/8Xm/8bm/8vo/8vp///vvG0/EaN0QZrN8/e+W+Tz/2OQsXSs1v/ShP+ZM////////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABMALAAAAAAQABAAAAVz4DQ9ZGmS4tisbLs6qiQrdM0uztNItOzzigZOp5AhjshjYliEOJ9QSCLXjEanj6r1iS0yvuAwA9tQiMXLXMNAi7jfkcRh2CgQCIm8O08o0AcDAACBCXGBAHQBAosCAQF5jQE4IwuVlpVLlTAiDp2en50iIQA7); }

.wk-document-legacy .dps-resource .sample-plan:before,
.wk-document-legacy .dps-resource .background:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQQALvh/8Dj/8Xm/7vi/8bm/8GAP5rN8+Tz/3Ss1v/dav/jk2OQsf/wxG0/Ef///5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABAALAAAAAAQABAAAAVwIAQhJPKcZ0GK4+EeDyMzRXMsreOgKV0bC8RBF5vVZA2gkPco+BqMZBA2o0GjCumyWqhls0pqFdsATx+KtLrcSGhh6rXbHUbHs+7G+5Ho+9sNAgR1fn96AQGEhW4AAwAAQCN6k5MGlpEiC5qbnJoiIQA7); }

.wk-document-legacy .dps-resource .example:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQfAF99AMDj/9fu/9HfnPe+W+n2/8vp/97w/+n1/93w/9Hr/8Dk/8Xm/97x/9ju/+r2/8bm/9Hs/+Pz/9jt/7vi/7vh/5rN82OQsW0/EXSs1uTz//+ZMwAAAJlmM////////yH5BAEAAB8ALAAAAAAQABAAAAWD4PdlZGmS4qitK9dy7qV6tKfB7q1ZV6bVHhhLx/PRhENi78ehaR6IghSBKLZYklVnu8X4GpzEoXE4JDpAr0YgcLAdEwF6MAB4uV1FRDGv39MGgWgAhH8EBBsYEAwMGI6PGWiHiQEBC5aVRWgbnBgVnxUUnzwfj48WqKmkIhetrq+tIiEAOw==); }

.wk-document-legacy .dps-resource .faq:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQANUgAMDj/7vi/9Hr/8Xm/7vh/8bm/97w/8Dk//e+W8vo/97x/9Lr/9ju/8DY8d3w/8zo/+Pz/9Hs/+r2/+n1/9Ls/9fu/8vp///ShG0/EZrN82OQsXSs1uTz//+ZM////5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAACAALAAAAAAQABAAAAaNQBBoQywaicIhZ8n5OD9MjkbpqX6qVQwzo9lwrJ6L+ILQcrhez1WMwZA7WvT308ZIPm/MRB7lYMJwHBByCgoOBmtwDQYGchUVDBV4cI+RchQCmXRtCwsRAnIJFqMPoxaio3IFAwMFTxirrXIHBwAAV3AAtQByBAG/mxi+BARcQxnIycrIU0Iaz9DRz0JBADs=); }

.wk-document-legacy .dps-resource .checklist:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQbAMXm/7vi/8vp/8Dk/8bm/9fu/97w/9Lr/8vo/+r2/+n1/9ju/9Hr/9Hs/+Pz/8Dj/7vh//e+W//ShG0/EZrN82OQsXSs1uTz//+ZM5lmM////////wAAAAAAAAAAAAAAACH5BAEAABsALAAAAAAQABAAAAWA4LZZZGmS4nitbLtWqiZrbpZdVGVdM81mmglOx5v9NBJhbte7JICSyESxXNmsyAhm4qhmJBpbdmswLCeYCHg8KSwKVbRkrp0cDg1GFYGO1AUIAoJVBABoWwAAhYpVAwMPExMPD4+PD1UQAZoBEJ2ZnTkjFKOkpaMwIhWqq6yqIiEAOw==); }

.wk-document-legacy .dps-resource .key-concepts:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdAGs8DLvh/8vp/8Dj/8Xm/8bm/97w/9fu/+Pz/5CQkNju/9Lr/9Hr/7vi/+r2/97x/8Dk/9Hs/8vo//e+W5rN82OQsXSs1m0/Ef/ShOTz//+ZM5lmM////////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWDYNdZZGla2yZaWeu+G4ddLGdzbxZjE53dONeu5wMKZT1KpXbLOGITjeaiZKWuu+kFUY0BkZeHwaDcYc7n6OWgOJRlaJ52EWG8MamL/CIR+O8bBHpqBQQEBV0zF4NTAxADA1U6E5SUUwENAQFKHQkUG1JSABSkpBUiHRUVAKwAqq+nHSEAOw==); }

.wk-document-legacy .dps-resource .tools:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQcAMDj/8vp///ShMXm/9Hr/9fu/8bm/8Dk/7vi/9Lr/97x/8vo/9Ls/97w/9ju/93w/+n2/9Hs/7vh//e+W20/EZrN83Ss1mOQseTz//+ZM5lmM////////wAAAAAAAAAAACH5BAEAABwALAAAAAAQABAAAAV+IMdZZGmS4oitLKa966Vu9LZqW6at1WVhNRtOx+r9gq7N7kbxAWtJgQaiETR/rah0krliFIpHA8fNmClGTKHgIHcp8EKaQXBTCIlEhJBe2AMBC4ABaXYGAwOHiUZ2BwAAjo4APTgUXQgIEpoSmRKUXGgVoqOjMhxwF6mqq6YhADs=); }

.wk-document-legacy .dps-resource .cch-pointer:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQYALvi/7yUacvp/8Dj/8Xm/97w/8bm/8Dk/9Lr/9Hr/9Hs/9ju/9fu//e+W7vh/5rN8+Tz/2OQsXSs1m0/Ef/ShP+ZM////5lmM////wAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABgALAAAAAAQABAAAAV1IIZJZGmS4gitbLtGqiVbwWW3TyRBcn3JF5wO4pNRbEibzmakOJ/OiW5igUYnWOzUCp0UCjnJhEvJLhjhSaPBnSAUiXSlsq5jBfg0ds6fGAgEBmEQBwFZWQMHAwODDgCPAA6SjpI5Iw+YmZqYMCIRn6ChnyIhADs=); }

.wk-document-legacy .dps-resource .cch-practice-tip:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAOZnALvh///omf/lj//jhf/trsbm/5J3Wsvp/2c1A//hfZS92eHu+cDj/3BDFZmWkHNHG6WASKyHS/jdiNHs/8vo/6GCY/vfgsrR1ZFoMal/Nn5bNphtLJuWjaq+zMjc6t/CeXROJ9LY229BEoVWF4FqUeDn7Y2Ab8Crls6yd8Dk/4ZaJbKMTKmmn8DGydLl87CNVIFrUd/UyXZJG6qtq8Xm/52kpbvi/8Slapyiou/ZnJbA34JpTqaUgZBxUayMYKqBOJxwKdfu/+jGav/wvcS+t+Px/Kq4wOTb0srn/PrmqNCsV9Ls/7ugfsCib2s8DHhHD97q88WlZmg3BryVQoxeHf3z0PLgraydjbrR4dHr/6B2NqSSfmo6CYVkQqqYhGs6CXVSLf/qo5rN82OQsXSs1uTz/////////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAGcALAAAAAAQABAAAAe6gGdnZISFhoSCg2WLizwLjItjimaUZiEIV5BlYmNkZZVmBkwiUJCcnpVHCFU+W6adRTEnFTJNQ1ZfPV5EJacGKhA3OQTESSgvGA8KnQtdEWHQ0WFRDRenZS4aKwHc3B9cLUHXSx5OEgLoAlosE1nXFEhSFgP0AxszBwfXBVhPCUIZlCT4wYFGgWspjFABAsLBjhFTTDBgcA0ADjA1bAAA0IEEjI2cBolRIKakSTE6SkoSNKaly5ctBQUCADs=); }

.wk-document-legacy .dps-resource .exam-standard:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQALMPALvi/45kOry8vLvh///wxP/jk5rN8//danSs1uTz/2OQsW0/Ef+ZM5lmM////////yH5BAEAAA8ALAAAAAAQABAAAARv8D1Eq6Vyps37VpojOh5nKEjSrKvKLsnJjs5CL82iNA5P/LYfwabj8QpIG7KwGO56jqUyyXzyDlgb9rCoGh1bbZb7XJgXDOKZfD0wAucAY55WnO+C+xmVGAD+AAOCfoInEwaIiYqIIBIKj5CRjxIRADs=); }

.wk-document-legacy .dps-resource .expert:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQZALvi/7vh/6wAAN3v9t7v9uPz/8LT1+n1/8Dj/97x/+H1+eTz/9IAAPe+W//wxP/dansAAP/ShJrN82OQsXSs1m0/Ef+ZM5lmM////////wAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABkALAAAAAAQABAAAAV8YJZRZGmS4nitbHsZ01hZFWbfdyVN1ExXwGBw12sYj8fKYkFcVCLQaLRyIA6eUmml0ExUHOBwWMcjKGo4DASCIVNYlYf8wVa7Lw/anG6D3B8+FoIWazRNQkEQDAwQCE0BAJEAAQECjAIBOyMSnJ0SjIqaIhOkpRMMpKgiIQA7); }

.wk-document-legacy .dps-resource .forms:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQALMLAP/dam0/EZrN82OQsf/wxHSs1sVrI/+ZMwAAAJlmM////////wAAAAAAAAAAAAAAACH5BAEAAAsALAAAAAAQABAAAARgcK1Cq6Vypc37DsOUKGRpKoEwFKPhvi+RriNh3/jMKnAvqzsD7mYA6Fo9A6J4VACeUAACYQQiewgFoikcZrVWXvKrpSkO6PSBrGUF3vD3d4qYCO74exahygz+gH9TfxIRADs=); }

.wk-document-legacy .dps-resource .legal:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdANfu/8bm//e+W8Xm/9Hr/8Dk/7vi/97x/9Hs/8vo/7vh/97w/93w/9Ls/9ju/9Lr/+Pz/8Dj/8vp///dav/ShJrN82OQsXSs1m0/EeTz//+ZM////5lmM////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWFYNddZGmS4pitbMa1lrrN28ptGFtZV0bXHAoGt9r1fhwNhkIhGn20DEYjYOKerQwkWR1ieJnDgbE4cCbTJeYJADgAZwwgDXg2CIQ4gvDA7J8JEnEShIGETwFxAQEDA4yOT3ERBRGTlhE7HHEGnAqan5oWcRWkFVxNFAIaHBitFq8WrbKzIQA7); }

.wk-document-legacy .dps-resource .policy:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQeAObm5svp/4yMjN7w/9fu/8Xm/7vh/8Dj/4CAgP+ZmaZ+Tsbm/9Hs/7vi/+Pz/9Hr/8Dk/9Lr/9ju//9mZv/wnnSs1prN820/EWOQsaampv+ZAJlmMwAAAP///////wAAACH5BAEAAB4ALAAAAAAQABAAAAWAoOdVZGlyqLetbHtxQJJwW2ffN9cB2UR3maAwo+NlBD+cjUNBBJGY2pBI6WgQSEtUybRqNByHVhrsfsGDwThXPXMIEsI6SDmDLxHGY22j1MEdFwGDcxkUCgocGYEFBQt8OkoXEAcHfEo2Fw0GBloXn6ChFxakGCIeGKmqqx2mHiEAOw==); }

.wk-document-legacy .dps-resource .best-practice:before {
  background-image: url(data:image/gif;base64,R0lGODlhEAAQAMQdAMvp/9fu/8Xm/8Dj/7vh/7vi/8Dk/8bm/9Hr/97w/9Lr/8vo/97x/+Pz/9ju/+n1/9Hs/9Ls//e+W//wxJrN8+Tz/3Ss1v/damOQsW0/Ef+ZM////5lmM////wAAAAAAACH5BAEAAB0ALAAAAAAQABAAAAWAYNdZJGeaJCmOVcVN8KRlbYWxG3dt23VJsxYFY3FNeDwfkDYsvpC9X7Bp3EWlmQf15etiG1QGt7tMJKiBwHgZcASoEQTitVQoIAjqAgCgz/Z8VAcCAhmGhoMCB1QGBgN0EhkDjgNUBAUFh4aXBARDIxShkBmhoTciGKkYh6qpIiEAOw==); }

.wk-document-legacy .dps-resource .BNanoteContainer {
  clear: right;
  margin: 0 45px 15px;
  border-style: solid;
  border-color: #9fb1bc;
  border-width: 1px;
  padding: 3px;
  float: right;
  width: 25%; }

.wk-document-legacy .dps-resource .BNanote {
  background-color: #4e82a3;
  color: white;
  font-size: medium;
  font-weight: bold;
  margin: 0;
  padding-left: 15px; }

.wk-document-legacy .dps-resource .BNanoteBody {
  margin-top: 0;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: small;
  margin: 0 5px 15px;
  color: #000000; }

.wk-document-legacy .dps-resource #footer {
  position: relative;
  padding: 0;
  margin: 0;
  height: 32px;
  _height: 31px;
  width: 100%;
  border-top: 1px solid #3366cc; }

.wk-document-legacy .dps-resource .footer {
  position: relative;
  padding: 0;
  margin: 0;
  height: 32px;
  _height: 31px;
  width: 100%;
  border-top: 1px solid #3366cc;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #757575;
  border-color: #d9dde7;
  padding: 20px 0 17px;
  margin-top: 20px; }

.wk-document-legacy .dps-resource .header {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #3366cc;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000000; }

.wk-document-legacy .dps-resource .blueRule {
  width: 100%;
  margin: 10px;
  border-top: 1px solid #3366cc; }

.wk-document-legacy .dps-resource #document-separator {
  width: 100%;
  border-bottom: 5px solid #33dddd; }

.wk-document-legacy .dps-resource .smallText {
  padding: 0;
  margin: 0;
  font-size: 11px;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  color: #000000; }

.wk-document-legacy .dps-resource .hideText {
  display: none; }

.wk-document-legacy .dps-resource .fracNum {
  vertical-align: .2em; }

.wk-document-legacy .dps-resource .fracDen {
  vertical-align: -.2em; }

.wk-document-legacy .dps-resource #menupathIFrame {
  width: 100%;
  height: 0;
  border: 0 none;
  margin: 0; }

.wk-document-legacy .dps-resource .headnote {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .history {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .source-info {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .reference-line {
  font: small Arial, Helvetica, sans-serif; }

.wk-document-legacy .dps-resource .balloonHelpIcon {
  height: 10px;
  width: 10px;
  cursor: help;
  margin-left: 2px;
  margin-right: 2px; }

.wk-document-legacy .dps-resource .not-indented-level {
  margin-left: 0; }

.wk-document-legacy .dps-resource .indented-level {
  margin-left: 20pt;
  margin-left: 0; }
  .wk-document-legacy .dps-resource .indented-level > .indented-level {
    margin-left: 20px; }

.wk-document-legacy .dps-resource .menupath-link {
  display: inline;
  padding-left: 5px; }

.wk-document-legacy .dps-resource .historyVaNum {
  padding-right: 6px;
  white-space: nowrap;
  vertical-align: top; }

.wk-document-legacy .dps-resource .relateBlock {
  display: none;
  display: none; }

.wk-document-legacy .dps-resource .docNotInFocus {
  background-color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:link {
    color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:active {
    color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:visited {
    color: #eeeeee; }
  .wk-document-legacy .dps-resource .docNotInFocus a.metadataLink:hover {
    color: #eeeeee; }

.wk-document-legacy .dps-resource .accmenu {
  font-size: .9em;
  border: 1px solid #a0a0a0;
  width: 180px;
  padding: 5px;
  position: absolute;
  background-color: #f0f0f0;
  border-radius: 3px;
  box-shadow: 0 0 5px #aaaaaa; }
  .wk-document-legacy .dps-resource .accmenu .option {
    padding: 2px 0;
    cursor: pointer; }
    .wk-document-legacy .dps-resource .accmenu .option:hover {
      background-color: #e0f1c6; }
    .wk-document-legacy .dps-resource .accmenu .option.disabled {
      color: gray;
      cursor: default; }
      .wk-document-legacy .dps-resource .accmenu .option.disabled:hover {
        background-color: #f0f0f0; }
    .wk-document-legacy .dps-resource .accmenu .option .labelwrapper {
      display: inline-block;
      zoom: 1;
      *display: inline;
      vertical-align: top; }
    .wk-document-legacy .dps-resource .accmenu .option .img {
      cursor: pointer;
      display: inline-block;
      zoom: 1;
      *display: inline;
      padding-right: 3px; }
    .wk-document-legacy .dps-resource .accmenu .option.printfragment .labelwrapper {
      margin-left: 2px; }

.wk-document-legacy .dps-resource .documentLevelNoteWrap {
  padding-bottom: 10px; }

.wk-document-legacy .dps-resource .sideNoteasFooter {
  border-top: 1px dashed black; }
  .wk-document-legacy .dps-resource .sideNoteasFooter table {
    width: 100%; }

.wk-document-legacy .dps-resource .topanswer {
  background-color: #e9f2f7;
  border: 1px solid #abcce2;
  padding: 6px;
  margin: 10px; }
  .wk-document-legacy .dps-resource .topanswer h2 {
    color: #006699;
    margin: 0 0 10px; }

.wk-document-legacy .dps-resource .docTitle {
  font-size: 22px;
  font-weight: 400;
  color: #323232;
  margin: 0 0 20px; }

.wk-document-legacy .dps-resource .docHeadSpacer {
  font-size: 16px;
  font-weight: 400; }

.wk-document-legacy .dps-resource .hP,
.wk-document-legacy .dps-resource .hP[style] {
  display: block !important;
  font-size: 17px !important; }

.wk-document-legacy .dps-resource .hP {
  color: #323232; }

.wk-document-legacy .dps-resource .docTitle,
.wk-document-legacy .dps-resource .leadIn,
.wk-document-legacy .dps-resource .level-heading {
  font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif; }
  .wk-document-legacy .dps-resource .docTitle a:hover,
  .wk-document-legacy .dps-resource .leadIn a:hover,
  .wk-document-legacy .dps-resource .level-heading a:hover {
    text-decoration: none; }

.wk-document-legacy .dps-resource .leadIn .smallCap, .wk-document-legacy .dps-resource .leadIn .smallCap[style],
.wk-document-legacy .dps-resource .level-heading .smallCap,
.wk-document-legacy .dps-resource .level-heading .smallCap[style] {
  font-size: 19px !important;
  font-weight: 700;
  line-height: normal; }

.wk-document-legacy .dps-resource .leadIn i,
.wk-document-legacy .dps-resource .level-heading i {
  font-style: normal; }

.wk-document-legacy .dps-resource .leadIn,
.wk-document-legacy .dps-resource .level-heading {
  font-size: 19px;
  font-weight: 700; }

.wk-document-legacy .dps-resource .leadIn {
  line-height: normal;
  margin-bottom: 4px; }
  .wk-document-legacy .dps-resource .leadIn > b {
    font-weight: normal; }

.wk-document-legacy .dps-resource .level-heading {
  margin: 0; }

.wk-document-legacy .dps-resource .smallLink a {
  font-size: 11px;
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  color: #0000cc;
  text-decoration: none; }
  .wk-document-legacy .dps-resource .smallLink a:hover {
    color: #0000ff;
    text-decoration: underline; }

.wk-document-legacy .dps-resource thead.xhtml {
  font-family: "Arial unicode ms", Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: normal; }

.wk-document-legacy .dps-resource hr.rule {
  color: black;
  background-color: black;
  border-width: thin;
  height: 1pt; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-none {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-yellow {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-pink {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-orange {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-green {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .hlOn .docSearchTerm span.hl-blue {
  background-color: #ffff66;
  font-weight: bold; }

.wk-document-legacy .dps-resource .dateUpdated .modifiedLabel {
  font-weight: bold; }

.wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] {
  margin-left: 0 !important; }
  .wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] tr[style] {
    padding-bottom: 0 !important; }
  .wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] .nav-caution-note {
    display: none; }
  .wk-document-legacy .dps-resource .nav-caution-note-wrapper[style] .cautionImgR {
    display: none; }

.wk-document-legacy .dps-resource .nav-caution-note {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAIAAAALR8HoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowQUZDM0E4NzgwRTAxMUU1QTk0Q0ZGRTI2OEM5NDEzNyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowQUZDM0E4ODgwRTAxMUU1QTk0Q0ZGRTI2OEM5NDEzNyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjBBRkMzQTg1ODBFMDExRTVBOTRDRkZFMjY4Qzk0MTM3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjBBRkMzQTg2ODBFMDExRTVBOTRDRkZFMjY4Qzk0MTM3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+SQxZMQAAAF1JREFUeNqU0ssJADEIBNB8StI2LN2ajOyKgUVWnZOHeUQwU0TGE2YeUQDA57ezusBMC2g2EbXA3a0OYvMPApOCr6kA7ZjRK6XAO2YQMX3BO6u+ks/T/079uEeAAQCPfDz5xZEe4wAAAABJRU5ErkJggg==);
  background-repeat: repeat;
  height: 100%;
  margin-left: -37px;
  position: absolute;
  width: 17px; }

.wk-document-legacy .dps-resource .cautionText {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAARElEQVQYlYXOMQ4AIAhD0crG/Q/bEReMARH+/NJ0mRlOJFElo1BVGYUv9QKAjCJ8+gmSBUqiWHpFRqUI6CcuaoSjXgDYStwm+pRLfNoAAAAASUVORK5CYII=) 0 0 repeat;
  border: 1px solid #a8a8a8;
  color: #000;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 25px;
  padding: 10px 10px;
  text-align: center; }
  .wk-document-legacy .dps-resource .cautionText:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015%2014%22%3E%3Cpath%20fill%3D%22%23EC373B%22%20d%3D%22M8.044%200.151C7.877%200.05%207.694%200%207.501%200%207.305%200%207.125%200.05%206.958%200.151%206.79%200.252%206.659%200.389%206.564%200.562L0.143%2012.41c-0.195%200.354-0.19%200.707%200.016%201.061%200.095%200.163%200.223%200.291%200.388%200.387C0.712%2013.953%200.889%2014%201.079%2014h12.843c0.188%200%200.367-0.047%200.531-0.143%200.163-0.096%200.294-0.224%200.388-0.387%200.206-0.354%200.212-0.707%200.018-1.061L8.438%200.562C8.342%200.389%208.211%200.252%208.044%200.151%22%2F%3E%3Cg%20id%3D%22XMLID_1_%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M8.57%209.97v1.601c0%200.08-0.03%200.14-0.08%200.199%20-0.05%200.051-0.11%200.08-0.19%200.08H6.7c-0.08%200-0.14-0.029-0.19-0.08%20-0.05-0.06-0.08-0.119-0.08-0.199V9.97c0-0.08%200.03-0.14%200.08-0.2C6.56%209.72%206.62%209.69%206.7%209.69h1.6c0.08%200%200.141%200.029%200.19%200.079C8.54%209.83%208.57%209.89%208.57%209.97zM8.71%204.56L8.55%208.42c0%200.061-0.03%200.1-0.09%200.14C8.41%208.6%208.35%208.62%208.27%208.62H6.72c-0.08%200-0.14-0.021-0.2-0.061C6.47%208.52%206.44%208.48%206.44%208.42L6.3%204.58c0-0.08%200.02-0.14%200.08-0.18%200.07-0.06%200.14-0.09%200.2-0.09h1.84c0.061%200%200.13%200.03%200.2%200.09C8.68%204.44%208.71%204.49%208.71%204.56z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 0 0;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    height: 15px;
    margin-right: 0.3125rem;
    position: relative;
    top: 1px;
    width: 15px; }

.wk-document-legacy .dps-resource .endnotes {
  border-top: 1px solid #dee3ea;
  margin-top: 35px; }
  .wk-document-legacy .dps-resource .endnotes .fnBoxL {
    display: none; }
  .wk-document-legacy .dps-resource .endnotes .endBoxTop > h1, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h2, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h3, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h4, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h5, .wk-document-legacy .dps-resource .endnotes .endBoxTop > h6 {
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px; }
  .wk-document-legacy .dps-resource .endnotes .endBoxBottom > table > tbody > tr[style] {
    padding-bottom: 0 !important; }
  .wk-document-legacy .dps-resource .endnotes .tableNote sup {
    top: auto; }
  .wk-document-legacy .dps-resource .endnotes .footnoteRef,
  .wk-document-legacy .dps-resource .endnotes .footnote {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .wk-document-legacy .dps-resource .endnotes .footnoteRef {
    font-size: 15px;
    font-weight: 700;
    position: relative;
    top: 1px; }
  .wk-document-legacy .dps-resource .endnotes .footnote {
    font-size: 15px !important;
    line-height: 18px;
    margin-bottom: 11px;
    margin-left: 15px; }

.wk-page-error {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center; }
  .wk-page-error:after {
    content: " ";
    display: block;
    clear: both; }

.wk-page-error.wk-page-error-small .wk-page-error-icon {
  font-size: 5rem; }

.wk-page-error.wk-page-error-small .wk-page-error-heading {
  font-size: 1.5rem; }

.wk-page-error.wk-page-error-small .wk-page-error-message {
  font-size: 1.1875rem;
  margin-bottom: 1.1875rem; }

.wk-page-error.wk-page-error-small .wk-page-error-text {
  font-size: 1rem; }

.wk-page-error.wk-page-error-small .wk-page-error-meta {
  font-size: 0.8125rem; }

.wk-page-error .wk-page-error-logo {
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 11.875rem; }
  @media (min-width: 37.5rem) {
    .wk-page-error .wk-page-error-logo {
      margin-bottom: 2.9375rem; } }

.wk-page-error .wk-page-error-icon {
  color: #dadada;
  font-size: 5rem;
  margin-bottom: 1rem; }
  @media (min-width: 37.5rem) {
    .wk-page-error .wk-page-error-icon {
      font-size: 8rem; } }

.wk-page-error .wk-page-error-heading {
  font-size: 2.0625rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0; }

.wk-page-error .wk-page-error-message {
  font-size: 1.4375rem;
  margin-bottom: 1.375rem; }

.wk-page-error .wk-page-error-text {
  color: #757575;
  font-size: 1.1875rem; }

.wk-page-error .wk-page-error-search {
  background-color: #ededed;
  margin-bottom: 1.5rem; }
  @media (min-width: 65rem) {
    .wk-page-error .wk-page-error-search {
      padding-left: 1rem;
      padding-right: 1rem; } }

.wk-page-error .wk-page-error-meta {
  margin-top: 1.25rem; }

.wk-page-error .wk-page-error-reference {
  color: #757575;
  font-size: 0.8125rem; }

.wk-pagination-bar {
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-weight: 400;
  margin-bottom: 1.25rem; }
  .wk-pagination-bar::after {
    clear: both;
    content: ' ';
    display: table; }
  @media (min-width: 37.5rem) {
    .wk-pagination-bar > .wk-pagination {
      float: right; } }
  .wk-pagination-bar > .wk-pagination-results {
    font-size: 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-pagination-bar > .wk-pagination-results {
        float: left; }
        .wk-pagination-bar > .wk-pagination-results .wk-field-item {
          margin-bottom: 0;
          margin-top: 0.125rem; }
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-field-header,
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-select-field {
            float: left; }
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-select-field {
            margin-bottom: 0; }
            .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-select-field::after {
              top: 1em; }
          .wk-pagination-bar > .wk-pagination-results .wk-field-item .wk-field-header {
            margin-right: 0.625rem;
            margin-top: 0.9375rem; } }

.wk-pagination {
  display: table;
  list-style-type: none;
  padding: 0;
  width: 100%; }
  @media (min-width: 37.5rem) {
    .wk-pagination {
      display: inline-block;
      width: auto; } }
  .wk-pagination > li {
    display: none;
    text-align: center;
    vertical-align: top; }
    @media (min-width: 37.5rem) {
      .wk-pagination > li {
        display: block;
        float: left; }
        .wk-pagination > li:last-child, .wk-pagination > li:first-child {
          display: inline-block; } }
    .wk-pagination > li > a {
      background-color: #a6d1ea;
      color: #007ac3;
      display: block;
      font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
      font-weight: 400;
      line-height: 1.875rem;
      min-height: 48px;
      padding: 0.5625rem 1.25rem;
      transition: background-color .1s ease-out; }
      .wk-pagination > li > a.wk-button {
        padding: 0.5625rem 0.8125rem;
        vertical-align: middle; }
        .wk-pagination > li > a.wk-button > * {
          vertical-align: middle; }
      .wk-pagination > li > a:hover {
        background-color: #80bde1;
        color: #007ac3; }
    .wk-pagination > li:last-child, .wk-pagination > li:first-child {
      display: table-cell; }
      .wk-pagination > li:last-child a, .wk-pagination > li:first-child a {
        background-color: #007ac3;
        color: #fff; }
      .wk-pagination > li:last-child a:hover, .wk-pagination > li:first-child a:hover {
        background-color: #409bd2; }
    .wk-pagination > li.wk-disabled {
      position: relative; }
      .wk-pagination > li.wk-disabled a,
      .wk-pagination > li.wk-disabled:first-child a,
      .wk-pagination > li.wk-disabled:last-child a {
        background: #dadada;
        color: #fff;
        cursor: not-allowed;
        pointer-events: none; }
    .wk-pagination > li.wk-disabled::before {
      background: transparent;
      content: '';
      cursor: not-allowed;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .wk-pagination > .wk-active {
    display: table-cell;
    width: 80%; }
    @media (min-width: 37.5rem) {
      .wk-pagination > .wk-active {
        width: auto; } }
    .wk-pagination > .wk-active a {
      background-color: #fff;
      color: #007ac3; }

.wk-list {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 0;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  list-style-type: none;
  padding: 0.84746% 0; }
  .wk-list > li,
  .wk-list dd,
  .wk-list dt {
    padding: 0.3125rem 1.69492%; }
  .wk-list > li:hover,
  .wk-list dd:hover {
    background-color: #ededed;
    cursor: pointer; }
  .wk-list > li:focus, .wk-list > li.wk-active,
  .wk-list dd:focus,
  .wk-list dd.wk-active {
    background-color: #409bd2;
    color: #fff; }
  .wk-list > dt {
    color: #ec5862; }
  .wk-list > dd,
  .wk-list li {
    margin-left: 0; }

.wk-modal {
  bottom: 0;
  display: none;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1050; }
  .wk-modal.wk-modal-opened {
    display: inline; }
  .wk-modal.wk-modal-small .wk-modal-content {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto; }
    .wk-modal.wk-modal-small .wk-modal-content:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) {
      .wk-modal.wk-modal-small .wk-modal-content {
        max-width: 28.125rem; } }
  .wk-modal.wk-modal-medium .wk-modal-content {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 37.5rem; }
    .wk-modal.wk-modal-medium .wk-modal-content:after {
      content: " ";
      display: block;
      clear: both; }
  .wk-modal.wk-modal-large .wk-modal-content {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto; }
    .wk-modal.wk-modal-large .wk-modal-content:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) and (max-width: 65rem) {
      .wk-modal.wk-modal-large .wk-modal-content {
        max-width: 37.5rem; } }
    @media (min-width: 65rem) {
      .wk-modal.wk-modal-large .wk-modal-content {
        max-width: 65rem; } }
  .wk-modal.wk-modal-with-overlay::before {
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1; }
  @media (min-width: 37.5rem) {
    .wk-modal.wk-modal-vertical-middle .wk-modal-content {
      left: 50%;
      margin-top: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
      width: 100%; } }
  @media (min-width: 37.5rem) {
    .wk-modal.wk-modal-vertical-middle.wk-modal-large-content .wk-modal-content {
      left: auto;
      margin-top: 1.875rem;
      position: static;
      top: auto;
      -ms-transform: none;
          transform: none; } }
  .wk-modal[wk-context*='success'] .wk-modal-content .wk-modal-header {
    background-color: #85bc20; }
  .wk-modal[wk-context*='success'] .wk-modal-content .wk-modal-close {
    color: #d4e8b1; }
  .wk-modal[wk-context*='warning'] .wk-modal-content .wk-modal-header {
    background-color: #ea8f00; }
  .wk-modal[wk-context*='warning'] .wk-modal-content .wk-modal-close {
    color: #f8d8a6; }
  .wk-modal[wk-context*='danger'] .wk-modal-content .wk-modal-header {
    background-color: #e5202e; }
  .wk-modal[wk-context*='danger'] .wk-modal-content .wk-modal-close {
    color: #f6b1b6; }
  .wk-modal .wk-modal-content {
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4); }
    @media (min-width: 37.5rem) {
      .wk-modal .wk-modal-content {
        margin-top: 1.875rem; } }
    .wk-modal .wk-modal-content .wk-modal-close {
      color: #a6d1ea;
      cursor: pointer;
      font-size: 1.375rem;
      position: absolute;
      right: 0;
      top: 6px;
      transition: all .1s ease-out;
      width: auto; }
    .wk-modal .wk-modal-content button.wk-button-icon.wk-modal-close {
      background-color: transparent;
      padding: 0.4375rem; }
    .wk-modal .wk-modal-content .wk-modal-close:hover {
      color: #fff; }
    .wk-modal .wk-modal-content .wk-modal-close:focus {
      color: #fff; }
    .wk-modal .wk-modal-content .wk-modal-header {
      background-color: #007ac3;
      min-height: 3rem;
      padding-bottom: 0.8125rem;
      padding-left: 1rem;
      padding-right: 3.75rem;
      padding-top: 0.8125rem;
      position: relative; }
      .wk-modal .wk-modal-content .wk-modal-header .wk-modal-title {
        color: #fff;
        font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin: 0; }
    .wk-modal .wk-modal-content .wk-modal-body {
      padding: 1rem;
      position: relative; }
    .wk-modal .wk-modal-content .wk-modal-footer {
      padding: 0 1rem 1rem; }

.wk-modal-body-hidden {
  overflow: hidden; }

.wk-nav {
  list-style: none;
  margin: 0;
  padding: 0; }
  .wk-nav::after {
    clear: both;
    content: ' ';
    display: table; }
  .wk-nav [wk-icon] {
    margin-right: 0.5em; }
  .wk-nav > li {
    display: block;
    float: none; }
    @media (min-width: 37.5rem) {
      .wk-nav > li {
        float: left; }
        .wk-nav > li::after {
          clear: both;
          content: ' ';
          display: table; } }
    .wk-nav > li > a {
      display: block;
      font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
      font-size: 0.9375rem;
      padding: 0.75rem 0.9375rem; }
    .wk-nav > li.active > a,
    .wk-nav > li[wk-active] > a,
    .wk-nav > li.wk-active > a {
      color: #000; }
  .wk-nav.wk-nav-tabs {
    border-bottom: 0;
    margin-bottom: -0.0625rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; }
    .wk-nav.wk-nav-tabs > li {
      display: inline-block; }
      @media (min-width: 37.5rem) {
        .wk-nav.wk-nav-tabs > li {
          float: none; }
          .wk-nav.wk-nav-tabs > li > a {
            border: 1px solid transparent;
            border-bottom: 0;
            margin-bottom: -0.0625rem; } }
      .wk-nav.wk-nav-tabs > li.active > a,
      .wk-nav.wk-nav-tabs > li[wk-active] > a,
      .wk-nav.wk-nav-tabs > li.wk-active > a {
        background-color: #fff;
        border: 1px solid #dadada;
        border-bottom: 0;
        color: #474747; }
      .wk-nav.wk-nav-tabs > li.active:first-child, .wk-nav.wk-nav-tabs > li.active:last-child, .wk-nav.wk-nav-tabs > li[wk-active]:first-child, .wk-nav.wk-nav-tabs > li[wk-active]:last-child, .wk-nav.wk-nav-tabs > li.wk-active:first-child, .wk-nav.wk-nav-tabs > li.wk-active:last-child {
        position: relative; }
        .wk-nav.wk-nav-tabs > li.active:first-child > a::before, .wk-nav.wk-nav-tabs > li.active:last-child > a::before, .wk-nav.wk-nav-tabs > li[wk-active]:first-child > a::before, .wk-nav.wk-nav-tabs > li[wk-active]:last-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:first-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:last-child > a::before {
          border-top: 1px solid #dadada;
          content: ' ';
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          width: 20px;
          z-index: 999; }
      .wk-nav.wk-nav-tabs > li[wk-active]:first-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:first-child > a::before {
        border-left: 1px solid #dadada;
        left: 0; }
      .wk-nav.wk-nav-tabs > li.active:last-child > a::before, .wk-nav.wk-nav-tabs > li.wk-active:last-child > a::before {
        border-right: 1px solid #dadada;
        right: 0; }
      .wk-nav.wk-nav-tabs > li.wk-active:only-child > a::before {
        border-right: none; }

html.touch .wk-tab-gradient .wk-nav-tabs {
  -webkit-overflow-scrolling: auto;
  overflow-x: scroll; }

.wk-nav-tab-bar {
  border-bottom: 1px solid #dadada;
  margin-bottom: 0.9375rem; }
  .wk-nav-tab-bar .wk-tab-bar-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
    .wk-nav-tab-bar .wk-tab-bar-container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-nav-tab-bar .wk-tab-bar-container {
        padding-left: 0;
        padding-right: 0; } }
    .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient {
      position: relative; }
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-before,
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-after {
        display: none;
        height: 97%;
        position: absolute;
        top: 0;
        width: 20px;
        z-index: 999; }
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-before {
        background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)) 0% 0%;
        left: 0; }
      .wk-nav-tab-bar .wk-tab-bar-container .wk-tab-bar-gradient .wk-tab-bar-gradient-after {
        background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0)) 0% 0%;
        right: 0; }

.wk-nav.wk-nav-stacked > li {
  display: block;
  float: none; }
  .wk-nav.wk-nav-stacked > li > a {
    display: block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.9375rem;
    padding: 0.75rem 0.9375rem; }

.wk-product-name-bar {
  background-color: #85bc20; }
  .wk-product-name-bar > .wk-product-name {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
    .wk-product-name-bar > .wk-product-name:after {
      content: " ";
      display: block;
      clear: both; }
    .wk-product-name-bar > .wk-product-name:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-product-name-bar > .wk-product-name {
        padding-left: 0;
        padding-right: 0; } }

.wk-product-name {
  background-color: #85bc20;
  color: #fff;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1.06667rem;
  font-weight: 300;
  padding: 0.78125rem 0.9375rem;
  text-align: right; }

.wk-navbar {
  background-color: #007ac3;
  position: relative;
  z-index: 1000; }
  .wk-navbar .wk-navbar-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #007ac3;
    position: static; }
    .wk-navbar .wk-navbar-container:after {
      content: " ";
      display: block;
      clear: both; }
  .wk-navbar .wk-product-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 2; }
    @media (min-width: 37.5rem) {
      .wk-navbar .wk-product-name {
        float: right;
        text-align: left; } }
    .wk-navbar .wk-product-name::after {
      background-color: #85bc20;
      bottom: 0;
      content: ' ';
      display: block;
      left: 0;
      margin-left: 50%;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      z-index: -1; }
  .wk-navbar .wk-more-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #007ac3;
    float: left;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif; }
    .wk-navbar .wk-more-menu > li {
      display: block; }
      .wk-navbar .wk-more-menu > li > a {
        display: block; }
    @media (min-width: 37.5rem) {
      .wk-navbar .wk-more-menu {
        display: none; } }
    .wk-navbar .wk-more-menu [wk-icon='menu'] {
      margin-right: 0.5em; }
    .wk-navbar .wk-more-menu .wk-icon-menu {
      display: inline-block;
      vertical-align: middle; }
      .wk-navbar .wk-more-menu .wk-icon-menu::before {
        font-size: 1.625rem;
        margin-left: 0;
        position: relative;
        top: -0.0625rem;
        vertical-align: middle; }
    .wk-navbar .wk-more-menu a {
      transition: background-color 0.1s ease-out;
      color: #fff;
      padding: 0.875rem 1rem 0.8125rem; }
      .wk-navbar .wk-more-menu a:focus, .wk-navbar .wk-more-menu a:hover {
        background-color: #80bde1;
        color: #fff; }
    .wk-navbar .wk-more-menu.open {
      background-color: #005b92; }
      .wk-navbar .wk-more-menu.open [wk-icon='menu']::before {
        content: ""; }
      .wk-navbar .wk-more-menu.open .wk-icon-menu::before {
        content: '\e833'; }
  .wk-navbar .wk-nav {
    display: none; }
    @media (min-width: 37.5rem) {
      .wk-navbar .wk-nav {
        display: block;
        float: left; } }
    .wk-navbar .wk-nav.open {
      display: block;
      position: absolute;
      top: 3rem;
      width: 100%;
      z-index: 1040; }
      @media (min-width: 37.5rem) {
        .wk-navbar .wk-nav.open {
          position: relative;
          top: 0;
          width: auto; } }
      .wk-navbar .wk-nav.open > li > a {
        background-color: rgba(0, 122, 195, 0.95); }
    .wk-navbar .wk-nav > li > a {
      transition: background-color 0.1s ease-out;
      background-color: #007ac3;
      color: #fff;
      padding: 0.84375rem 0.9375rem; }
      @media (min-width: 37.5rem) {
        .wk-navbar .wk-nav > li > a {
          border: 0;
          margin: 0; } }
    .wk-navbar .wk-nav > li.active > a,
    .wk-navbar .wk-nav > li[wk-active] > a,
    .wk-navbar .wk-nav > li.wk-active > a {
      background-color: #005b92; }
    .wk-navbar .wk-nav > li > a:focus, .wk-navbar .wk-nav > li > a:hover,
    .wk-navbar .wk-nav > li.active > a:focus,
    .wk-navbar .wk-nav > li.active > a:hover,
    .wk-navbar .wk-nav > li[wk-active] > a:focus,
    .wk-navbar .wk-nav > li[wk-active] > a:hover,
    .wk-navbar .wk-nav > li.wk-active > a:focus,
    .wk-navbar .wk-nav > li.wk-active > a:hover {
      background-color: #80bde1; }
    .wk-navbar .wk-nav.wk-nav-tabs {
      border: 0; }
      @media (min-width: 37.5rem) and (max-width: 65rem) {
        .wk-navbar .wk-nav.wk-nav-tabs {
          margin-left: 0.75rem; } }
      @media (min-width: 37.5rem) {
        .wk-navbar .wk-nav.wk-nav-tabs > li > a {
          margin-top: 0.375rem;
          padding: 0.375rem 0.625rem; } }
      .wk-navbar .wk-nav.wk-nav-tabs > li {
        margin-bottom: -0.0625rem; }
  .wk-navbar .wk-breadcrumb > li {
    color: #fff; }
    .wk-navbar .wk-breadcrumb > li:not(:first-child)::before {
      color: #80bde1; }
    .wk-navbar .wk-breadcrumb > li > a {
      color: #f6f6f6; }
    .wk-navbar .wk-breadcrumb > li > a:focus,
    .wk-navbar .wk-breadcrumb > li > a:hover {
      color: #d4e8b1; }

.wk-search-bar {
  background-color: #f6f6f6;
  padding-bottom: 1rem;
  padding-top: 1rem; }
  .wk-search-bar input.wk-search-input[type='search'] {
    border-color: transparent; }
  .wk-search-bar .wk-search {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem; }
    .wk-search-bar .wk-search:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 77rem) {
      .wk-search-bar .wk-search {
        padding-left: 0;
        padding-right: 0; } }

html.ie9 .wk-search,
html.no-flexbox .wk-search {
  display: block; }
  html.ie9 .wk-search .wk-select-field,
  html.no-flexbox .wk-search .wk-select-field {
    float: left; }
  html.ie9 .wk-search .wk-search-box,
  html.no-flexbox .wk-search .wk-search-box {
    margin-left: 7rem;
    width: auto; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-search .wk-search-box,
      html.no-flexbox .wk-search .wk-search-box {
        margin-left: 8rem; } }
  html.ie9 .wk-search .wk-search-container,
  html.no-flexbox .wk-search .wk-search-container {
    display: block;
    margin-right: 3rem;
    width: auto; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-search .wk-search-container,
      html.no-flexbox .wk-search .wk-search-container {
        margin-right: 5rem; } }
  html.ie9 .wk-search .wk-search-submit,
  html.no-flexbox .wk-search .wk-search-submit {
    float: right;
    height: 3rem;
    width: 3rem; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-search .wk-search-submit,
      html.no-flexbox .wk-search .wk-search-submit {
        width: 5rem; } }

.wk-search {
  -ms-flex-line-pack: stretch;
      align-content: stretch;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
  .wk-search .wk-select-field {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-order: 0;
        order: 0;
    width: 7rem; }
    @media (min-width: 37.5rem) {
      .wk-search .wk-select-field {
        width: 8rem; } }
    .wk-search .wk-select-field > select {
      background-color: #dadada;
      color: #474747;
      font-size: 0.87891rem; }
  .wk-search .wk-search-box {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    width: 100%; }
    .wk-search .wk-search-box input.wk-search-input[type='search'] {
      border-left-color: transparent; }
      .wk-search .wk-search-box input.wk-search-input[type='search']:hover {
        border-left-color: #474747; }
      .wk-search .wk-search-box input.wk-search-input[type='search']:focus, .wk-search .wk-search-box input.wk-search-input[type='search']:hover:focus {
        border-color: #409bd2; }
  .wk-search .wk-search-box,
  .wk-search .wk-search-container {
    position: relative; }
    .wk-search .wk-search-box > .wk-list,
    .wk-search .wk-search-container > .wk-list {
      display: none; }
  .wk-search .wk-list {
    margin: 0;
    max-height: 12.5rem;
    overflow: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2000; }
    @media (min-width: 37.5rem) {
      .wk-search .wk-list {
        max-height: 18.75rem; } }
    .wk-search .wk-list.wk-active {
      display: block; }

.wk-search-container {
  -ms-flex-line-pack: stretch;
      align-content: stretch;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-order: 0;
      order: 0;
  width: 100%; }

input.wk-search-input[type='search'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #dadada;
  border-radius: 0;
  color: #474747;
  font-family: "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  font-size: 1rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.6875rem 1rem;
  width: 100%;
  border-right-color: transparent;
  box-sizing: border-box;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-order: 1;
      order: 1;
  overflow: hidden;
  text-overflow: ellipsis; }
  input.wk-search-input[type='search'][disabled] {
    background-color: #ededed;
    color: #a3a3a3;
    cursor: not-allowed; }
  input.wk-search-input[type='search']:focus, input.wk-search-input[type='search']:hover:focus {
    border-color: #409bd2; }
  input.wk-search-input[type='search']:hover {
    border-color: #474747; }
  input.wk-search-input[type='search']::-ms-clear {
    height: 0;
    width: 0; }
  input.wk-search-input[type='search']:-moz-placeholder, input.wk-search-input[type='search']::-moz-placeholder, input.wk-search-input[type='search']:-ms-input-placeholder, input.wk-search-input[type='search']::-webkit-input-placeholder {
    color: #a3a3a3; }

.wk-search-submit {
  background-color: #007ac3;
  color: #fff;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 1.3rem;
  margin-bottom: 0;
  -ms-flex-order: 1;
      order: 1;
  padding: 0 0.375rem;
  text-align: center;
  width: auto; }
  @media (min-width: 37.5rem) {
    .wk-search-submit {
      padding: 0 0.9375rem; } }
  .wk-search-submit:hover:not([disabled]) {
    background-color: #409bd2; }
  .wk-search-submit [wk-icon='search'] {
    font-size: 1.1rem; }
  @media (min-width: 37.5rem) {
    .wk-search-submit [wk-icon='search'],
    .wk-search-submit .wk-icon-search {
      display: none; } }
  .wk-search-submit .wk-search-button-text {
    display: none; }
    @media (min-width: 37.5rem) {
      .wk-search-submit .wk-search-button-text {
        display: inline;
        font-size: 0.87891rem; } }

.wk-search-results {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em; }
  .wk-search-results:after {
    content: " ";
    display: block;
    clear: both; }
  .wk-search-results.clustered .wk-search-results-group {
    border: 1px solid #a6d1ea;
    margin-bottom: .8em;
    padding: .5em; }
    @media (min-width: 37.5rem) {
      .wk-search-results.clustered .wk-search-results-group {
        width: 49.15254%;
        float: left;
        margin-right: 1.69492%;
        margin-bottom: 1rem; }
        .wk-search-results.clustered .wk-search-results-group:nth-child(even) {
          float: right;
          margin-right: 0; } }
    .wk-search-results.clustered .wk-search-results-group dd:last-of-type {
      margin-bottom: 0; }
  .wk-search-results.clustered a.show-more {
    display: block;
    margin-top: 1rem; }

dl.wk-search-results-list {
  margin: 0; }
  dl.wk-search-results-list dt {
    margin-bottom: .5rem; }
    dl.wk-search-results-list dt .definition {
      color: #757575;
      font-weight: 200;
      margin-bottom: .1rem;
      text-transform: uppercase; }
    dl.wk-search-results-list dt .title {
      color: #007ac3;
      cursor: pointer;
      text-decoration: none; }
      dl.wk-search-results-list dt .title:hover {
        color: #85bc20; }
  dl.wk-search-results-list dd {
    color: #a3a3a3;
    margin: 0 0 1rem; }

.wk-content-filter-layout {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .wk-content-filter-layout:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 37.5rem) and (max-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-pane {
      width: 32.20339%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-pane {
      width: 23.72881%;
      float: left;
      margin-right: 1.69492%; } }
  @media (min-width: 37.5rem) and (max-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-results {
      width: 66.10169%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; } }
  @media (min-width: 57rem) {
    .wk-content-filter-layout .wk-content-filter-results {
      width: 74.57627%;
      float: left;
      margin-right: 1.69492%;
      float: right;
      margin-right: 0; } }

.wk-content-filter {
  margin-bottom: 0.625rem; }
  .wk-content-filter a {
    font-size: 0.87891rem; }

.wk-content-filter-title {
  color: #474747;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0;
  text-transform: uppercase; }

.wk-content-filter-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .wk-content-filter-list > li {
    display: block; }
    .wk-content-filter-list > li > a {
      display: block; }
  .wk-content-filter-list > li {
    margin-bottom: 0.0625rem; }
    .wk-content-filter-list > li::after {
      clear: both;
      content: ' ';
      display: table; }
    .wk-content-filter-list > li > a {
      background: #ededed;
      color: #757575;
      font-size: 0.87891rem;
      font-weight: 400;
      padding: 0.875rem 0.625rem; }
      .wk-content-filter-list > li > a .wk-content-filter-label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .wk-content-filter-list > li > a .wk-badge {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: transparent;
        border-radius: 0;
        color: #757575;
        float: right;
        font-size: 0.87891rem;
        font-weight: 300;
        margin-left: 0.5rem;
        max-width: 3.75rem;
        padding: 0;
        top: auto; }
        @media (min-width: 37.5rem) and (max-width: 57rem) {
          .wk-content-filter-list > li > a .wk-badge {
            display: none; } }
    .wk-content-filter-list > li:last-child {
      margin-bottom: 0; }
    .wk-content-filter-list > li.active > a,
    .wk-content-filter-list > li[wk-active] > a,
    .wk-content-filter-list > li.wk-active > a {
      background: #757575;
      color: #fff; }
      .wk-content-filter-list > li.active > a .wk-badge,
      .wk-content-filter-list > li[wk-active] > a .wk-badge,
      .wk-content-filter-list > li.wk-active > a .wk-badge {
        color: #fff; }
    .wk-content-filter-list > li.wk-disabled > a,
    .wk-content-filter-list > li[wk-disabled] > a {
      cursor: default; }
      .wk-content-filter-list > li.wk-disabled > a .wk-badge,
      .wk-content-filter-list > li[wk-disabled] > a .wk-badge {
        display: none; }
  .wk-content-filter-list > li > a:focus,
  .wk-content-filter-list > li:hover > a {
    background-color: #dadada;
    color: #757575; }
  .wk-content-filter-list > li.wk-disabled > a,
  .wk-content-filter-list > li[wk-disabled] > a,
  .wk-content-filter-list > li.wk-disabled > a:focus,
  .wk-content-filter-list > li[wk-disabled] > a:focus,
  .wk-content-filter-list > li.wk-disabled:hover > a,
  .wk-content-filter-list > li[wk-disabled]:hover > a {
    background-color: #ededed;
    color: #bfbfbf; }

.wk-breadcrumb-bar {
  background-color: #f6f6f6;
  margin-bottom: 1.69492%;
  position: relative;
  z-index: 1; }
  .wk-breadcrumb-bar .wk-breadcrumb-container {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f6f6; }
    .wk-breadcrumb-bar .wk-breadcrumb-container:after {
      content: " ";
      display: block;
      clear: both; }

.wk-breadcrumb {
  display: none;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0;
  list-style-type: none;
  margin: 0;
  padding-bottom: 0.84375rem;
  padding-top: 0.84375rem; }
  .wk-breadcrumb:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 77rem) {
    .wk-breadcrumb {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 37.5rem) {
    .wk-breadcrumb {
      display: block;
      float: left; } }
  .wk-breadcrumb > li {
    color: #a3a3a3;
    display: inline-block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 0.9375rem;
    font-weight: 300; }
    .wk-breadcrumb > li [wk-icon] {
      margin-right: 0.4375rem; }
    .wk-breadcrumb > li:first-child {
      font-weight: 500; }
    .wk-breadcrumb > li:not(:first-child)::before {
      color: #a3a3a3;
      content: "»";
      margin-left: 0.25rem;
      margin-right: 0.25rem; }

.wk-page-content {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem; }
  .wk-page-content:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 77rem) {
    .wk-page-content {
      padding-left: 0;
      padding-right: 0; } }

.wk-tooltip {
  background: #fff;
  border: 1px solid #ededed;
  box-shadow: 4px 5px 7px -3px rgba(99, 99, 99, 0.3);
  color: #474747;
  font-size: 0.77248rem;
  padding: 0.3125rem;
  visibility: hidden; }
  .wk-tooltip.wk-position-bottom::after, .wk-tooltip.wk-position-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-bottom::after {
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-tooltip.wk-position-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: #ededed;
    left: 50%;
    margin-left: -8px; }
  .wk-tooltip.wk-position-bottom-left::after, .wk-tooltip.wk-position-bottom-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-bottom-left::after {
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-tooltip.wk-position-bottom-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: #ededed;
    left: 0;
    margin-left: 20px; }
  .wk-tooltip.wk-position-bottom-right::after, .wk-tooltip.wk-position-bottom-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-bottom-right::after {
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-tooltip.wk-position-bottom-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: #ededed;
    right: 0;
    margin-right: 20px; }
  .wk-tooltip.wk-position-top::after, .wk-tooltip.wk-position-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-top::after {
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-tooltip.wk-position-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: #ededed;
    left: 50%;
    margin-left: -8px; }
  .wk-tooltip.wk-position-top-left::after, .wk-tooltip.wk-position-top-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-top-left::after {
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-tooltip.wk-position-top-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: #ededed;
    left: 0;
    margin-left: 20px; }
  .wk-tooltip.wk-position-top-right::after, .wk-tooltip.wk-position-top-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-top-right::after {
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-tooltip.wk-position-top-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: #ededed;
    right: 0;
    margin-right: 20px; }
  .wk-tooltip.wk-position-left::after, .wk-tooltip.wk-position-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-left::after {
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-tooltip.wk-position-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: #ededed;
    top: 50%;
    margin-top: -8px; }
  .wk-tooltip.wk-position-left-bottom::after, .wk-tooltip.wk-position-left-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-left-bottom::after {
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-tooltip.wk-position-left-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: #ededed;
    top: 0;
    margin-top: 5px; }
  .wk-tooltip.wk-position-left-top::after, .wk-tooltip.wk-position-left-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-left-top::after {
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-tooltip.wk-position-left-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: #ededed;
    bottom: 0;
    margin-bottom: 5px; }
  .wk-tooltip.wk-position-right::after, .wk-tooltip.wk-position-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-right::after {
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-tooltip.wk-position-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: #ededed;
    top: 50%;
    margin-top: -8px; }
  .wk-tooltip.wk-position-right-bottom::after, .wk-tooltip.wk-position-right-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-right-bottom::after {
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-tooltip.wk-position-right-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: #ededed;
    top: 0;
    margin-top: 5px; }
  .wk-tooltip.wk-position-right-top::after, .wk-tooltip.wk-position-right-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-tooltip.wk-position-right-top::after {
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-tooltip.wk-position-right-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: #ededed;
    bottom: 0;
    margin-bottom: 5px; }

.wk-popover {
  background: #fff;
  border: 1px solid #ededed;
  box-shadow: 4px 5px 7px -3px rgba(99, 99, 99, 0.3);
  max-width: 300px; }
  .wk-popover.wk-position-bottom::after, .wk-popover.wk-position-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-bottom::after {
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-popover.wk-position-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: #ededed;
    left: 50%;
    margin-left: -8px; }
  .wk-popover.wk-position-bottom-left::after, .wk-popover.wk-position-bottom-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-bottom-left::after {
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-popover.wk-position-bottom-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: #ededed;
    left: 0;
    margin-left: 20px; }
  .wk-popover.wk-position-bottom-right::after, .wk-popover.wk-position-bottom-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-bottom-right::after {
    border-width: 7px;
    top: -14px;
    border-bottom-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-popover.wk-position-bottom-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    top: -16px;
    border-bottom-color: #ededed;
    right: 0;
    margin-right: 20px; }
  .wk-popover.wk-position-top::after, .wk-popover.wk-position-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-top::after {
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 50%;
    margin-left: -7px; }
  .wk-popover.wk-position-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: #ededed;
    left: 50%;
    margin-left: -8px; }
  .wk-popover.wk-position-top-left::after, .wk-popover.wk-position-top-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-top-left::after {
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    left: 0;
    margin-left: 21px; }
  .wk-popover.wk-position-top-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: #ededed;
    left: 0;
    margin-left: 20px; }
  .wk-popover.wk-position-top-right::after, .wk-popover.wk-position-top-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-top-right::after {
    border-width: 7px;
    bottom: -14px;
    border-top-color: #fff;
    right: 0;
    margin-right: 21px; }
  .wk-popover.wk-position-top-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    bottom: -16px;
    border-top-color: #ededed;
    right: 0;
    margin-right: 20px; }
  .wk-popover.wk-position-left::after, .wk-popover.wk-position-left::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-left::after {
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-popover.wk-position-left::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: #ededed;
    top: 50%;
    margin-top: -8px; }
  .wk-popover.wk-position-left-bottom::after, .wk-popover.wk-position-left-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-left-bottom::after {
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-popover.wk-position-left-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: #ededed;
    top: 0;
    margin-top: 5px; }
  .wk-popover.wk-position-left-top::after, .wk-popover.wk-position-left-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-left-top::after {
    border-width: 7px;
    left: -14px;
    border-right-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-popover.wk-position-left-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    left: -16px;
    border-right-color: #ededed;
    bottom: 0;
    margin-bottom: 5px; }
  .wk-popover.wk-position-right::after, .wk-popover.wk-position-right::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-right::after {
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 50%;
    margin-top: -7px; }
  .wk-popover.wk-position-right::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: #ededed;
    top: 50%;
    margin-top: -8px; }
  .wk-popover.wk-position-right-bottom::after, .wk-popover.wk-position-right-bottom::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-right-bottom::after {
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    top: 0;
    margin-top: 6px; }
  .wk-popover.wk-position-right-bottom::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: #ededed;
    top: 0;
    margin-top: 5px; }
  .wk-popover.wk-position-right-top::after, .wk-popover.wk-position-right-top::before {
    border: solid rgba(255, 255, 255, 0);
    content: ' ';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0; }
  .wk-popover.wk-position-right-top::after {
    border-width: 7px;
    right: -14px;
    border-left-color: #fff;
    bottom: 0;
    margin-bottom: 6px; }
  .wk-popover.wk-position-right-top::before {
    border-color: rgba(255, 255, 255, 0);
    border-width: 8px;
    right: -16px;
    border-left-color: #ededed;
    bottom: 0;
    margin-bottom: 5px; }
  .wk-popover .wk-popover-body {
    padding: 0.625rem; }

.wk-user-popover .wk-user-popover-section {
  border-bottom: 1px solid #ededed;
  padding: 0.625rem; }
  .wk-user-popover .wk-user-popover-section:nth-child(even) {
    background: #f6f6f6; }
  .wk-user-popover .wk-user-popover-section:last-child {
    border-bottom: 0; }
  .wk-user-popover .wk-user-popover-section .wk-module-heading {
    margin: 0;
    margin-bottom: 10px; }

.wk-card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .wk-card-group > .wk-card {
    margin-bottom: 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-card-group > .wk-card {
        float: none; } }

.wk-card-group-4 > .wk-card,
.wk-card-group-3 > .wk-card,
.wk-card-group-2 > .wk-card {
  margin-bottom: 0.9375rem;
  width: 100%; }

@media (min-width: 37.5rem) {
  .wk-card-group-2 > .wk-card {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-2 > .wk-card:nth-child(2n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 37.5rem) and (max-width: 65rem) {
  .wk-card-group-3 > .wk-card,
  .wk-card-group-4 > .wk-card {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-3 > .wk-card:nth-child(2n),
    .wk-card-group-4 > .wk-card:nth-child(2n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 65rem) {
  .wk-card-group-3 > .wk-card {
    width: 32.20339%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-3 > .wk-card:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 65rem) {
  .wk-card-group-4 > .wk-card {
    width: 23.72881%;
    float: left;
    margin-right: 1.69492%; }
    .wk-card-group-4 > .wk-card:nth-child(4n) {
      float: right;
      margin-right: 0; } }

html.ie9 .wk-card-group,
html.no-flexbox .wk-card-group,
.wk-card-group-float.wk-card-group {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  display: block; }
  html.ie9 .wk-card-group:after,
  html.no-flexbox .wk-card-group:after,
  .wk-card-group-float.wk-card-group:after {
    content: " ";
    display: block;
    clear: both; }
  html.ie9 .wk-card-group > .wk-card,
  html.no-flexbox .wk-card-group > .wk-card,
  .wk-card-group-float.wk-card-group > .wk-card {
    position: relative; }
    @media (min-width: 37.5rem) {
      html.ie9 .wk-card-group > .wk-card,
      html.no-flexbox .wk-card-group > .wk-card,
      .wk-card-group-float.wk-card-group > .wk-card {
        max-height: 11.25rem;
        min-height: 11.25rem;
        overflow: hidden; }
        html.ie9 .wk-card-group > .wk-card::after,
        html.no-flexbox .wk-card-group > .wk-card::after,
        .wk-card-group-float.wk-card-group > .wk-card::after {
          background: transparent linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%) repeat scroll 0% 0%;
          bottom: -1px;
          content: '';
          display: block;
          height: 30px;
          left: 1px;
          position: absolute;
          right: 1px; } }

.wk-card,
a.wk-card {
  background-color: #fff;
  border: 1px solid #dadada;
  font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
  padding: 0.9375rem; }
  .wk-card .wk-card-meta,
  .wk-card .wk-card-title,
  a.wk-card .wk-card-meta,
  a.wk-card .wk-card-title {
    color: #007ac3; }
  .wk-card .wk-card-title,
  a.wk-card .wk-card-title {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    margin-top: 0; }
  .wk-card .wk-card-body,
  a.wk-card .wk-card-body {
    color: #474747; }
  .wk-card .wk-label,
  .wk-card .wk-label-success,
  .wk-card .wk-label-info,
  .wk-card .wk-label-danger,
  a.wk-card .wk-label,
  a.wk-card .wk-label-success,
  a.wk-card .wk-label-info,
  a.wk-card .wk-label-danger {
    float: right;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem; }
  .wk-card:focus, .wk-card:hover,
  a.wk-card:focus,
  a.wk-card:hover {
    background: #d4e8b1; }
    .wk-card:focus .wk-card-title,
    .wk-card:focus .wk-card-meta,
    .wk-card:focus .wk-card-body, .wk-card:hover .wk-card-title,
    .wk-card:hover .wk-card-meta,
    .wk-card:hover .wk-card-body,
    a.wk-card:focus .wk-card-title,
    a.wk-card:focus .wk-card-meta,
    a.wk-card:focus .wk-card-body,
    a.wk-card:hover .wk-card-title,
    a.wk-card:hover .wk-card-meta,
    a.wk-card:hover .wk-card-body {
      color: #000; }

.wk-login-screen {
  background-color: #fff;
  bottom: 0;
  color: #474747;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%; }
  .wk-login-screen .wk-header .wk-brand,
  .wk-login-screen .wk-header .wk-logo {
    float: none; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-header .wk-brand,
      .wk-login-screen .wk-header .wk-logo {
        float: left; } }
  .wk-login-screen .wk-header .wk-product-brand {
    color: #474747;
    display: block;
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    font-size: 1.21363rem;
    font-weight: 300;
    margin: 0.625rem 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-header .wk-product-brand {
        float: right;
        margin: 0;
        margin-top: 1.625rem; } }
    .wk-login-screen .wk-header .wk-product-brand strong {
      font-weight: 500; }
  .wk-login-screen .wk-login-layout,
  .wk-login-screen .wk-login-layout-options,
  .wk-login-screen .wk-login-layout-support {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-login-layout,
      .wk-login-screen .wk-login-layout-options,
      .wk-login-screen .wk-login-layout-support {
        padding-left: 0;
        padding-right: 0; } }
    .wk-login-screen .wk-login-layout .wk-copyright-text,
    .wk-login-screen .wk-login-layout-options .wk-copyright-text,
    .wk-login-screen .wk-login-layout-support .wk-copyright-text {
      color: #474747;
      font-size: 0.82397rem;
      font-weight: 300;
      margin: 1rem 0; }
  .wk-login-screen .wk-login-layout .wk-login-footer,
  .wk-login-screen .wk-login-layout .wk-login-form {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto; }
    .wk-login-screen .wk-login-layout .wk-login-footer:after,
    .wk-login-screen .wk-login-layout .wk-login-form:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-login-layout .wk-login-footer,
      .wk-login-screen .wk-login-layout .wk-login-form {
        max-width: 28.125rem; } }
  .wk-login-screen .wk-login-layout-options .wk-login-footer,
  .wk-login-screen .wk-login-layout-options .wk-login-form {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 37.5rem; }
    .wk-login-screen .wk-login-layout-options .wk-login-footer:after,
    .wk-login-screen .wk-login-layout-options .wk-login-form:after {
      content: " ";
      display: block;
      clear: both; }
  .wk-login-screen .wk-login-layout-support {
    padding: 0; }
    .wk-login-screen .wk-login-layout-support .wk-login-support-container,
    .wk-login-screen .wk-login-layout-support .wk-login-footer {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem; }
      .wk-login-screen .wk-login-layout-support .wk-login-support-container:after,
      .wk-login-screen .wk-login-layout-support .wk-login-footer:after {
        content: " ";
        display: block;
        clear: both; }
      @media (min-width: 77rem) {
        .wk-login-screen .wk-login-layout-support .wk-login-support-container,
        .wk-login-screen .wk-login-layout-support .wk-login-footer {
          padding-left: 0;
          padding-right: 0; } }
    @media (min-width: 37.5rem) {
      .wk-login-screen .wk-login-layout-support .wk-login-form {
        width: 57.62712%;
        float: left;
        margin-right: 1.69492%; } }
    .wk-login-screen .wk-login-layout-support .wk-login-support {
      border: 1px solid #ededed;
      color: #474747;
      margin-bottom: 1rem;
      padding: 1rem; }
      @media (min-width: 37.5rem) {
        .wk-login-screen .wk-login-layout-support .wk-login-support {
          width: 40.67797%;
          float: left;
          margin-right: 1.69492%;
          float: right;
          margin-right: 0; } }
      .wk-login-screen .wk-login-layout-support .wk-login-support h5 {
        margin-top: 0; }
      .wk-login-screen .wk-login-layout-support .wk-login-support strong {
        color: #474747; }
      .wk-login-screen .wk-login-layout-support .wk-login-support ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .wk-login-screen .wk-login-layout-support .wk-login-support ul li {
          clear: both;
          padding: 0.125rem 0;
          white-space: pre-wrap;
          word-wrap: break-word; }
          .wk-login-screen .wk-login-layout-support .wk-login-support ul li > [wk-icon] {
            font-size: 1rem;
            margin-right: .5em; }
            @media (min-width: 37.5rem) and (max-width: 54rem) {
              .wk-login-screen .wk-login-layout-support .wk-login-support ul li > [wk-icon] {
                display: none; } }
  .wk-login-screen .wk-login-form {
    background-color: #ededed;
    margin-bottom: 1rem;
    padding: 1rem; }
    .wk-login-screen .wk-login-form h3 {
      color: #474747;
      font-family: "Franziska", "Times New Roman", Times, serif;
      font-style: italic;
      margin: 0 0 1rem; }
    .wk-login-screen .wk-login-form p {
      font-size: 1rem; }
    .wk-login-screen .wk-login-form .wk-button-full {
      margin-bottom: 0; }
    .wk-login-screen .wk-login-form .forgot-link {
      display: block;
      margin-bottom: 0.9375rem; }

.wk-login-form-container {
  margin-bottom: 1rem; }
  @media (min-width: 37.5rem) {
    .wk-login-form-container {
      margin-bottom: 0; } }
  @media (min-width: 37.5rem) {
    .wk-login-form-container.has-login-options {
      max-width: 75rem;
      margin-left: auto;
      margin-right: auto; }
      .wk-login-form-container.has-login-options:after {
        content: " ";
        display: block;
        clear: both; }
      .wk-login-form-container.has-login-options .wk-login-fields,
      .wk-login-form-container.has-login-options .wk-login-options {
        width: 48.97959%;
        float: left;
        margin-right: 2.04082%; }
      .wk-login-form-container.has-login-options .wk-login-options {
        float: right;
        margin-right: 0;
        margin-top: 1.5625rem; } }

.wk-breakpoint-tag {
  background-color: #474747;
  bottom: 10px;
  color: #474747;
  opacity: .3;
  padding: 6px;
  position: fixed;
  right: 10px;
  text-shadow: 0 1px 0 #000;
  z-index: 1200; }
  .wk-breakpoint-tag::before {
    content: 'mobile'; }
    @media (min-width: 37.5rem) and (max-width: 65rem) {
      .wk-breakpoint-tag::before {
        content: 'tablet'; } }
    @media (min-width: 65rem) {
      .wk-breakpoint-tag::before {
        content: 'desktop'; } }
    @media (min-width: 75rem) {
      .wk-breakpoint-tag::before {
        content: "desktop (max 75rem)"; } }

.wk-a11y-enhanced-focus a:hover:focus,
.wk-a11y-enhanced-focus :focus {
  outline-color: #000;
  outline-offset: 0;
  outline-style: solid;
  outline-width: 2px; }

.wk-navbar-dropdown-container {
  background: #005b92;
  color: #fff;
  display: none; }
  .wk-navbar-dropdown-container::after {
    clear: both;
    content: ' ';
    display: table; }
  .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
      -ms-flex: 0 1 auto;
          flex: 0 1 auto; }
      @media (min-width: 0) and (max-width: 37.5rem) {
        .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
          width: 100%;
          float: left;
          margin-left: 0;
          margin-right: 0; }
          .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column:nth-child(1n) {
            margin-right: 0; } }
      @media (min-width: 37.5rem) and (max-width: 65rem) {
        .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
          width: 47.82609%;
          float: left;
          margin-right: 4.34783%; }
          .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column:nth-child(2n) {
            margin-right: 0; } }
      @media (min-width: 65rem) {
        .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column {
          width: 21.73913%;
          float: left;
          margin-right: 4.34783%; }
          .wk-navbar-dropdown-container .wk-navbar-dropdown-column-group > .wk-navbar-dropdown-column:nth-child(4n) {
            margin-right: 0; } }
  .wk-navbar-dropdown-classic.wk-navbar-dropdown-container {
    overflow: hidden; }
    .wk-navbar-dropdown-classic.wk-navbar-dropdown-container .wk-nav > li {
      position: relative; }

.wk-navbar-dropdown {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0;
  padding-top: 0.5rem; }
  .wk-navbar-dropdown:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 77rem) {
    .wk-navbar-dropdown {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 37.5rem) {
    .wk-navbar-dropdown {
      padding-bottom: 2.125rem;
      padding-top: 0; } }
  @media (min-width: 77rem) {
    .wk-navbar-dropdown {
      margin-left: auto;
      margin-right: auto;
      max-width: 75rem; } }
  .wk-navbar-dropdown .wk-navbar-dropdown-column {
    padding-bottom: 0;
    padding-top: 0.9375rem; }
    @media (min-width: 37.5rem) {
      .wk-navbar-dropdown .wk-navbar-dropdown-column {
        padding-top: 3rem; } }
  @media (min-width: 77rem) {
    .wk-navbar-dropdown-classic.wk-navbar-dropdown {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .wk-navbar-dropdown ul, .wk-navbar-dropdown li, .wk-navbar-dropdown dd, .wk-navbar-dropdown figure {
    margin: 0;
    padding: 0; }
  .wk-navbar-dropdown li {
    list-style-type: none; }
  .wk-navbar-dropdown .wk-dropdown-column-list li, .wk-navbar-dropdown .wk-dropdown-column-list dt, .wk-navbar-dropdown .wk-dropdown-column-list dd, .wk-navbar-dropdown [wk-dropdown-column*='list'] li, .wk-navbar-dropdown [wk-dropdown-column*='list'] dt, .wk-navbar-dropdown [wk-dropdown-column*='list'] dd {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.25rem; }
    @media (min-width: 37.5rem) {
      .wk-navbar-dropdown .wk-dropdown-column-list li, .wk-navbar-dropdown .wk-dropdown-column-list dt, .wk-navbar-dropdown .wk-dropdown-column-list dd, .wk-navbar-dropdown [wk-dropdown-column*='list'] li, .wk-navbar-dropdown [wk-dropdown-column*='list'] dt, .wk-navbar-dropdown [wk-dropdown-column*='list'] dd {
        margin-bottom: 0.875rem; } }
  .wk-navbar-dropdown .wk-dropdown-column-list dt, .wk-navbar-dropdown [wk-dropdown-column*='list'] dt {
    opacity: 0.5; }
  .wk-navbar-dropdown .wk-dropdown-column-list a, .wk-navbar-dropdown [wk-dropdown-column*='list'] a {
    color: #fff;
    display: block; }
    @media (min-width: 37.5rem) {
      .wk-navbar-dropdown .wk-dropdown-column-list a, .wk-navbar-dropdown [wk-dropdown-column*='list'] a {
        display: inline; } }
    .wk-navbar-dropdown .wk-dropdown-column-list a:hover, .wk-navbar-dropdown [wk-dropdown-column*='list'] a:hover {
      text-decoration: underline; }
  .wk-navbar-dropdown li, .wk-navbar-dropdown dd > figcaption {
    margin-bottom: 0.875rem; }

.wk-nav > li:hover .wk-navbar-dropdown-container {
  display: block;
  left: 0;
  position: relative;
  width: 100%; }
  @media (min-width: 37.5rem) {
    .wk-nav > li:hover .wk-navbar-dropdown-container {
      position: absolute; } }

.wk-nav > li:hover .wk-navbar-dropdown-container.wk-navbar-dropdown-classic {
  left: inherit;
  width: auto; }

.wk-navbar .wk-nav > li > a:hover,
.wk-navbar .wk-nav > li > a:focus,
.wk-navbar .wk-nav > li.active > a:focus {
  background-color: #005b92; }

.wk-dropdown {
  display: inline-block;
  position: relative; }
  .wk-dropdown .wk-dropdown-toggle::after {
    content: "";
    font-family: "wk-icons-open";
    font-size: .75em;
    margin-left: 0.5em; }
  .wk-dropdown .wk-dropdown-menu {
    border: 1px solid #a3a3a3;
    box-shadow: 0 0 8px #757575;
    display: none;
    position: absolute;
    top: 100%;
    z-index: 1040; }
    .wk-dropdown .wk-dropdown-menu.wk-dropdown-left {
      left: 0; }
    .wk-dropdown .wk-dropdown-menu.wk-dropdown-right {
      right: 0; }
    .wk-dropdown .wk-dropdown-menu.wk-dropdown-up {
      bottom: 100%;
      top: auto; }
  .wk-dropdown:hover > .wk-dropdown-menu, .wk-dropdown.wk-dropdown-open > .wk-dropdown-menu {
    display: block; }

.wk-dropdown-menu {
  background: #fff;
  min-width: 200px;
  width: 100%; }
  @media (min-width: 37.5rem) {
    .wk-dropdown-menu {
      width: auto; } }
  .wk-dropdown-menu > ul {
    list-style-type: none;
    margin: 0;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0; }
    .wk-dropdown-menu > ul li {
      min-width: 200px; }
      .wk-dropdown-menu > ul li > a {
        background: #fff;
        color: #757575;
        display: block;
        font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
        padding: 0.3125rem 0.9375rem; }
        .wk-dropdown-menu > ul li > a:hover {
          background: #ededed; }
  .wk-dropdown-menu > dl {
    font-family: "Fira Sans", "Helvetica Neue", Helvetica, "Roboto", Arial, sans-serif;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%; }
    .wk-dropdown-menu > dl dt,
    .wk-dropdown-menu > dl dd {
      display: block;
      min-width: 200px;
      padding: 0.9375rem; }
      .wk-dropdown-menu > dl dt > a,
      .wk-dropdown-menu > dl dd > a {
        color: #757575;
        display: block;
        padding: 0.3125rem 0.9375rem; }
        .wk-dropdown-menu > dl dt > a:hover,
        .wk-dropdown-menu > dl dd > a:hover {
          background: #ededed; }
    .wk-dropdown-menu > dl dt {
      color: #ec5862;
      margin: 0;
      padding-bottom: 0; }
    .wk-dropdown-menu > dl dd {
      margin: 0;
      padding: 0; }

.wk-header .wk-more-menu.open {
  background: rgba(71, 71, 71, 0.95); }

.wk-header .wk-nav.open {
  background: none; }
  .wk-header .wk-nav.open > li > a {
    background: rgba(71, 71, 71, 0.95);
    color: #fff; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav.open > li > a {
        background: none;
        color: #757575; } }
  .wk-header .wk-nav.open > li > a:hover {
    background: #474747; }
    @media (min-width: 37.5rem) {
      .wk-header .wk-nav.open > li > a:hover {
        background: none;
        color: #85bc20; } }

.wk-header .wk-nav .wk-dropdown .wk-dropdown-menu {
  background: none;
  border: 0;
  box-shadow: none;
  position: relative; }
  @media (min-width: 37.5rem) {
    .wk-header .wk-nav .wk-dropdown .wk-dropdown-menu {
      position: absolute; } }

.wk-header .wk-nav .wk-dropdown .wk-dropdown-body {
  background: rgba(71, 71, 71, 0.95);
  min-width: 200px;
  padding: 0.9375rem 0.9375rem; }

.wk-header .wk-nav .wk-dropdown:hover,
.wk-header .wk-nav .wk-dropdown.wk-dropdown-open {
  background: none; }
  .wk-header .wk-nav .wk-dropdown:hover > .wk-dropdown-toggle,
  .wk-header .wk-nav .wk-dropdown.wk-dropdown-open > .wk-dropdown-toggle {
    background: rgba(71, 71, 71, 0.95);
    color: #fff; }

.wk-header .wk-nav .wk-dropdown-menu a {
  background: rgba(71, 71, 71, 0.95);
  color: #fff;
  padding: 0.625rem 0.9375rem; }

.wk-header .wk-nav .wk-dropdown-menu a:hover {
  background: #474747; }

button.wk-dropdown-toggle {
  margin-bottom: 0; }

.wk-switch {
  position: relative;
  font-size: 0.875rem; }
  .wk-switch .wk-switch-checkbox {
    opacity: 0;
    position: absolute; }
  .wk-switch .wk-switch-base {
    transition: all 0.25s ease-out;
    background-color: #bfbfbf;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    height: 1.375rem;
    margin-right: 0.5rem;
    position: relative;
    text-align: left;
    vertical-align: middle;
    width: 2.75rem; }
    .wk-switch .wk-switch-base::after {
      background-color: #fff;
      border: 0.0625rem #bfbfbf solid;
      border-radius: 0;
      box-shadow: 0 0 1px #bfbfbf;
      content: '';
      display: inline-block;
      position: absolute;
      height: 1.375rem;
      left: 0;
      top: 0;
      width: 1.375rem;
      transition: all 0.25s ease-out; }
    .wk-switch .wk-switch-base::before {
      line-height: 1.375rem;
      padding-left: 0.3125rem; }
  .wk-switch .wk-switch-placeholder::after {
    clear: both;
    content: ' ';
    display: table; }
  .wk-switch input:checked ~ .wk-switch-base {
    background-color: #85bc20; }
    .wk-switch input:checked ~ .wk-switch-base::after {
      left: 1.375rem;
      border-color: #85bc20;
      box-shadow: 0 0 1px #85bc20; }
  .wk-switch input:focus ~ .wk-switch-base::after {
    outline: solid 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color; }
  .wk-switch input[disabled] ~ .wk-switch-base {
    background-color: #ededed;
    cursor: default; }
    .wk-switch input[disabled] ~ .wk-switch-base::after {
      border-color: #ededed; }
  .wk-switch .wk-switch-text {
    display: inline;
    margin-right: 0.5rem;
    vertical-align: middle; }
