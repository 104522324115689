////
/// @group wk-css.components.tooltip
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.14.0
/// @access public
////

@import 'tooltip.variables';
@import 'tooltip.mixins';

@include wk-exports('tooltip') {

    %scss-deps-tooltip {
        utils: wk-utils-dependencies(
            function wk-primary-color,
            mixin wk-tooltip,
            variable wk-font-scale
        );
    }

    .wk-tooltip {
        @include wk-tooltip($wk-tooltip-background-color, $wk-tooltip-border, $wk-tooltip-border-color, $wk-tooltip-box-shadow);
        color: $wk-tooltip-text-color;
        font-size: map-get($wk-font-scale, tiny);
        padding: $wk-tooltip-body-padding;
        visibility: hidden;
    }

}
