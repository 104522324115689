////
/// @group wk-css.components.switch
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.20.0
/// @access public
////

/// Creates a Switch base
///
/// @param {String} $wk-switch-base-background - background color of label
/// @param {Unit} $wk-switch-base-height - height of Switch base
/// @param {Unit} $wk-switch-base-width - width of Switch base
/// @param {String} $wk-switch-base-transition-duration - transition duration of Switch base
@mixin wk-switch-base(
    $wk-switch-base-background: $wk-switch-default-color,
    $wk-switch-base-height: $wk-switch-height,
    $wk-switch-base-width: $wk-switch-width,
    $wk-switch-transition-duration: $wk-switch-transition-duration,
    $wk-switch-base-color: $wk-switch-icon-color
) {
    @include wk-transition(all, $wk-switch-transition-duration, ease-out);

    background-color: $wk-switch-base-background;
    border-radius: $wk-switch-border-radius;
    color: $wk-switch-base-color;
    cursor: pointer;
    display: inline-block;
    height: $wk-switch-base-height;
    margin-right: wk-rem(8px);
    position: relative;
    text-align: left;
    vertical-align: middle;
    width: $wk-switch-base-width*2;
}

/// Creates a Switch pointer
///
/// @param {String} $wk-switch-pointer-background-color - background color of pointer
/// @param {Unit} $wk-switch-pointer-height - height of Switch pointer
/// @param {Unit} $wk-switch-pointer-width - width of Switch pointer
/// @param {String} $wk-switch-pointer-border - border of Switch pointer
/// @param {String} $wk-switch-pointer-box-shadow - transition duration of Switch pointer
/// @param {String} $wk-switch-pointer-transition-duration - transition duration of Switch pointer
@mixin wk-switch-base-pointer(
    $wk-switch-pointer-background-color: $wk-switch-pointer-color,
    $wk-switch-pointer-height: $wk-switch-height,
    $wk-switch-pointer-width: $wk-switch-width,
    $wk-switch-pointer-border: $wk-switch-border-width $wk-switch-default-color,
    $wk-switch-pointer-box-shadow:  $wk-switch-box-shadow $wk-switch-default-color,
    $wk-switch-pointer-transition-duration:  $wk-switch-transition-duration
) {
    background-color: $wk-switch-pointer-background-color;
    border: $wk-switch-pointer-border solid;
    border-radius: $wk-switch-border-radius;
    box-shadow: $wk-switch-pointer-box-shadow;
    content: '';
    display: inline-block;
    position: absolute;
    height: $wk-switch-pointer-height;
    left: 0;
    top: 0;
    width: $wk-switch-pointer-width;
    @include wk-transition(all, $wk-switch-pointer-transition-duration, ease-out);
}

/// Switch pointer properties in active state
///
/// @param {Unit} $wk-switch-pointer-active-left - active state position of pointer
/// @param {String} $wk-switch-pointer-active-border-color - active state border-color of Switch pointer
/// @param {String} $wk-switch-pointer-active-box-shadow - active state box-shadow of Switch pointer
@mixin wk-switch-pointer-active(
    $wk-switch-pointer-active-left: $wk-switch-width,
    $wk-switch-pointer-active-border-color: $wk-switch-active-color,
    $wk-switch-pointer-active-box-shadow: $wk-switch-box-shadow $wk-switch-active-color
) {
    left: $wk-switch-pointer-active-left;
    border-color:  $wk-switch-pointer-active-border-color;
    box-shadow: $wk-switch-pointer-active-box-shadow;
}

/// Switch base properties in active state
///
/// @param {String} $wk-switch-base-active-background-color - background color of pointer in active state
@mixin wk-switch-base-active(
    $wk-switch-base-active-background-color: $wk-switch-active-color
) {
    background-color: $wk-switch-base-active-background-color;
}

/// Outline for adding to non-focusable elements
@mixin wk-default-outline {
    outline: solid 2px Highlight;
    outline: auto 5px -webkit-focus-ring-color;
}
