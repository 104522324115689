////
/// @group wk-css.components.alerts
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.7.0
/// @access public
////

@import 'alerts.variables';
@import 'alerts.mixins';
@import 'alerts.placeholders';

@include wk-exports('alerts') {

    .wk-alert {
        @extend %wk-alert;

        &[wk-context*='info'] {
            @extend %wk-alert-info;
        }

        &[wk-context*='success'] {
            @extend %wk-alert-success;
        }

        &[wk-context*='warning'] {
            @extend %wk-alert-warning;
        }

        &[wk-context*='danger'] {
            @extend %wk-alert-danger;
        }
    }

    .wk-alert-info {
        @extend %wk-alert;
        @extend %wk-alert-info;
    }

    .wk-alert-success {
        @extend %wk-alert;
        @extend %wk-alert-success;
    }

    .wk-alert-warning {
        @extend %wk-alert;
        @extend %wk-alert-warning;
    }

    .wk-alert-danger {
        @extend %wk-alert;
        @extend %wk-alert-danger;
    }

    .wk-alert-dismissible {
        @extend %wk-alert-dismissible;
    }

    .wk-alert-fixed {
        @extend %wk-alert-fixed;
    }
}
