////
/// @group wk-css.components.page-error
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.21.0
/// @access public
////

@import 'page-error.variables';
@import 'page-error.placeholders';

.wk-page-error {
    @extend %wk-page-error;

    &.wk-page-error-small {

        .wk-page-error-icon {
            @extend %wk-page-error-icon-small;
        }

        .wk-page-error-heading {
            @extend %wk-page-error-heading-small;
        }

        .wk-page-error-message {
            @extend %wk-page-error-message-small;
        }

        .wk-page-error-text {
            @extend %wk-page-error-text-small;
        }

        .wk-page-error-meta {
            @extend %wk-page-error-meta-small;
        }
    }

    .wk-page-error-logo {
        @extend %wk-page-error-logo;
    }

    .wk-page-error-icon {
        @extend %wk-page-error-icon;
    }

    .wk-page-error-heading {
        @extend %wk-page-error-heading;
    }

    .wk-page-error-message {
        @extend %wk-page-error-message;
    }

    .wk-page-error-text {
        @extend %wk-page-error-text;
    }

    .wk-page-error-search {
        @extend %wk-page-error-search;
    }

    .wk-page-error-meta {
        @extend %wk-page-error-meta;
    }

    .wk-page-error-reference {
        @extend %wk-page-error-reference;
    }
}
