////
/// @group wk-css.components.alerts
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.21.1
/// @access public
////

/// Creates an alert style variation
///
/// @param $border-color {Color} Alert border color
/// @param $color {Color} Alert color
/// @param $icon-bg-color {Color} Alert icon background color
@mixin wk-alert-variant($border-color, $color, $icon-bg-color) {
    border-color: $border-color;
    color: $color;

    &[wk-icon]::before {
        background-color: $icon-bg-color;
    }
}
