////
/// @group wk-css.components.page-error
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.21.0
/// @access public
////

%wk-page-error {
    @include wk-layout-centered-medium;
    font-family: $wk-page-error-font-family;
    margin-bottom: $wk-page-error-margin-bottom;
    margin-top: $wk-page-error-margin-top;
    text-align: $wk-page-error-text-align;
}

%wk-page-error-icon-small {
    font-size: $wk-page-error-small-icon-font-size;
}

%wk-page-error-heading-small {
    font-size: $wk-page-error-small-heading-font-size;
}

%wk-page-error-message-small {
    font-size: $wk-page-error-small-message-font-size;
    margin-bottom: $wk-page-error-small-message-margin-bottom;
}

%wk-page-error-text-small {
    font-size: $wk-page-error-small-text-font-size;
}

%wk-page-error-meta-small {
    font-size: $wk-page-error-small-meta-font-size;
}

%wk-page-error-logo {
    margin-bottom: $wk-page-error-logo-margin-bottom;
    margin-left: $wk-page-error-logo-margin-left;
    margin-right: $wk-page-error-logo-margin-right;
    max-width: $wk-page-error-logo-max-width;

    @include breakpoint($wk-target-tablet-desktop) {
        margin-bottom: $wk-page-error-logo-tablet-desktop-margin-bottom;    
    }
}

%wk-page-error-icon {
    color: $wk-page-error-icon-color;
    font-size: $wk-page-error-icon-font-size;
    margin-bottom: $wk-page-error-icon-margin-bottom;

    @include breakpoint($wk-target-tablet-desktop) {
        font-size: $wk-page-error-icon-tablet-desktop-font-size;
    }
}

%wk-page-error-heading {
    font-size: $wk-page-error-heading-font-size;
    font-weight: $wk-page-error-heading-font-weight;
    margin-bottom: $wk-page-error-heading-margin-bottom;
    margin-top: $wk-page-error-heading-margin-top;
}

%wk-page-error-message {
    font-size: $wk-page-error-message-font-size;
    margin-bottom: $wk-page-error-message-margin-bottom;
}

%wk-page-error-text {
    color: $wk-page-error-text-color;
    font-size: $wk-page-error-text-font-size;
}

%wk-page-error-search {
    background-color: $wk-page-error-search-bg-color;
    margin-bottom: $wk-page-error-search-margin-bottom;

    @include breakpoint($wk-target-desktop) {
        padding-left: $wk-page-error-search-padding-left;
        padding-right: $wk-page-error-search-padding-right;
    }
}

%wk-page-error-meta {
    margin-top: $wk-page-error-meta-margin-top;
}

%wk-page-error-reference {
    color: $wk-page-error-reference-color;
    font-size: $wk-page-error-reference-font-size;
}
