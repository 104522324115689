////
/// @group wk-css.components.switch
/// @author [GPO](https://gpo.wolterskluwer.com/)
/// @since 0.20.0
/// @access public
////

/// Declares properties for Switch
%wk-switch {
    position: relative;
    font-size: $wk-switch-label-font-size;

    .wk-switch-checkbox {
        opacity: 0;
        position: absolute;
    }

    .wk-switch-base {
        @include wk-switch-base();

        &::after {
            @include wk-switch-base-pointer();
        }

        &::before {
            line-height: $wk-switch-height;
            padding-left: $wk-switch-icon-space-left;
        }
    }

    .wk-switch-placeholder {
        @include wk-clearfix;
    }

    input {

        &:checked ~ .wk-switch-base {
            @include wk-switch-base-active();

            &::after {
                @include wk-switch-pointer-active();
            }
        }

        &:focus ~ .wk-switch-base::after {
            @include wk-default-outline;
        }

        &[disabled] ~ .wk-switch-base {
            background-color: $wk-switch-disabled-color;
            cursor: $wk-switch-disabled-cursor;

            &::after {
                border-color: $wk-switch-disabled-color;
            }
        }
    }

    .wk-switch-text {
        display: $wk-switch-text-display;
        margin-right: $wk-switch-text-margin-right;
        vertical-align: $wk-switch-text-vertical-align;
    }
}


